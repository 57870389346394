import classnames from 'classnames'
import * as React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './Item.module.scss'

interface ItemProps {
  empty?: boolean
  onRemove?: () => void
  error: boolean
  label: string
  linkTo: string
}

const REMOVAL_SYMBOL = '✕'

export default class Item extends React.Component<ItemProps> {
  public render() {
    const { empty, error, label, linkTo, onRemove, ...restProps } = this.props
    return (
      <li className={styles['item']}>
        <NavLink
          className={classnames(styles['item-link'], error ? styles['is-error'] : empty ? styles['is-empty'] : null)}
          activeClassName={styles['is-active']}
          exact={true}
          replace={true}
          to={linkTo}
          {...restProps}
        >
          {label}
        </NavLink>
        {onRemove && <div className={styles['remove-button']} onClick={onRemove}>{REMOVAL_SYMBOL}</div>}
      </li>
    )
  }
}
