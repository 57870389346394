import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

import ErrorMessage from '../components/ErrorMessage'

import clearServerError from '../actions/clearServerError'

const mapStateToProps = (state, ownProps) => {
  const messages = _.chain(ownProps.errorKeys).map((val, key) => {
    if (key === 'client') {
      return _.result(state.clientErrors, val)
    } else {
      return _.result(state.serverErrors, val)
    }
  }).compact().uniqWith(_.isEqual).value()
  return { messages }
}

const mapDispatchToProps = dispatch => {
  return {
    onClearServerError: (key) => dispatch(clearServerError(key)),
  }
}

@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
export default class ErrorMessageList extends React.Component {
  static propTypes = {
    errorKeys: PropTypes.shape({
      client: PropTypes.string,
      server: PropTypes.string,
    }).isRequired,
    format: PropTypes.string.isRequired,
    messages: PropTypes.array.isRequired,
    onClearServerError: PropTypes.func,
  }

  clearServerError() {
    if (this.props.onClearServerError) { this.props.onClearServerError(this.props.errorKeys.server) }
  }

  constructErrorMessage(message, format) {
    return format.replace('__message__', message)
  }

  render() {
    return (
      _.map(this.props.messages, (mes, idx) => <ErrorMessage key={idx} text={this.constructErrorMessage(mes, this.props.format)} />)
    )
  }
}
