import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './description.module.scss'

const Description = ({ content, title, url, linkTarget }) => (
  <div className={styles['container']}>
    <p className={classnames(styles['content'], 'js-box-snap__description__content')} title="">{content}</p>
    <a className={classnames(styles['readmore'], 'js-hover')} href={url} title={title} target={linkTarget}>&gt; 続きを読む</a>
  </div>
)

Description.defaultProps = {
}

Description.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  linkTarget: PropTypes.string,
}

export default Description
