import _ from 'lodash'

import { SET_CLIENT_ERRORS } from '../actions/setClientErrors'

const initialState = false

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_CLIENT_ERRORS: return _.isEmpty(action.errors)
  default: return state
  }
}
