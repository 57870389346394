import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import connectMainContent from './connectMainContent'
import PhotoPreview from '../components/PhotoPreview'
import NailTagSelector from '../components/Nail/NailTagSelector'

import setAttribute from '../actions/setAttribute'

import styles from './MainContent.scss'

const mapStateToProps = (state, ownProps) => {
  const index = ownProps.match.params.index || 0
  return {
    index: index,
    nailTagNames: _.result(state.payload, `nailItems[${index}].nailTagNames`) || [],
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  const idx = ownProps.match.params.index || 0
  return {
    onUpdateNailTags: tags => {
      const genre = _.isEmpty(tags) ? '' : 'nail'
      dispatch(setAttribute(`nailItems[${idx}].nailTagNames`, tags))
      dispatch(setAttribute(`nailItems[${idx}].genre`, genre))
    }
  }
}

@connectMainContent('nailItems', mapStateToProps, mapDispatchToProps)
export default class nail extends React.Component {
  static propTypes = {
    imageSrc: PropTypes.string.isRequired,
    index: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    boundingBox: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number,
      bottom: PropTypes.number,
      right: PropTypes.number
    }),
    contentKey: PropTypes.string.isRequired,
    nailTagNames: PropTypes.array.isRequired,
    onUpdateNailTags: PropTypes.func,
    onCropComplete: PropTypes.func,
  }

  render() {
    const errorKeyBase = `${this.props.contentKey}[${this.props.index}]`
    return (
      <div className={styles['main-content__container']}>
        <div className={styles['main-content__preview']}>
          <PhotoPreview
            errorKeys={{ client: `${errorKeyBase}.boundingBox`, server: `${errorKeyBase}.boundingBox` }}
            errorFormatKey='nail_items'
            imageSrc={this.props.imageSrc}
            onCropComplete={this.props.onCropComplete}
            boundingBox={this.props.boundingBox} />
        </div>
        <div className={styles['main-content__form-area']}>
          <div className={styles['main-content__form-item']}>
            <NailTagSelector
              errorKeys={{ client: `${errorKeyBase}.nailTagNames`, server: `${errorKeyBase}.nailTags` }}
              index={this.props.index}
              tagNames={this.props.nailTagNames}
              onUpdate={this.props.onUpdateNailTags} />
          </div>
        </div>
      </div>
    )
  }
}
