import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { createHashHistory } from 'history'

import reducers from '../reducers'
import epics from '../epics'

export const history = createHashHistory()

let composeEnhancers
if (process.env.NODE_ENV === 'production') {
  composeEnhancers = compose
} else {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

const epicMiddleware = createEpicMiddleware()
const middleware = applyMiddleware(
  routerMiddleware(history),
  epicMiddleware,
)

export default function configureStore(initialState) {
  const store = createStore(
    combineReducers({
      ...reducers,
      router: connectRouter(history),
    }),
    initialState,
    composeEnhancers(middleware),
  )
  epicMiddleware.run(epics)
  return store
}
