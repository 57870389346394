import React, { useCallback, useRef } from 'react'

import AutocompletableInput from './AutocompletableInput'
import Caption from './Caption'
import Error from './Error'
import StockPreview from './Item/KeywordTagSelector/StockPreview'

import styles from './TagSelector.module.scss'

const TagSelector = ({
  tagType, captionText, captionNote, tagNames,
  errorFormat, errorKeys, stockPreview = false, onUpdate,
}) => {
  const errorContainerRef = useRef(null)

  const handleUpdate = useCallback((tags) => {
    onUpdate(tags)
  }, [onUpdate])

  const handleBlur = useCallback(() => {
    if (errorContainerRef.current != null) {
      errorContainerRef.current.clearServerError()
    }
  }, [])

  const error = errorKeys ? (
    <Error
      errorRef={errorContainerRef}
      errorKeys={errorKeys}
      format={errorFormat}
    />
  ) : null

  const keyword = tagNames.join(' ')

  return (
    <div>
      {error}
      <Caption
        text={captionText}
        note={captionNote}
      />
      <div className={styles['tags-area']}>
        <AutocompletableInput
          tagType={tagType}
          multi={true}
          tagNames={tagNames}
          onBlur={handleBlur}
          onUpdate={handleUpdate}
        />
        {stockPreview ? <StockPreview keyword={keyword} /> : null}
      </div>
    </div>
  )
}

export default TagSelector
