import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import connectMainContent from './connectMainContent'
import Bubble from '../components/Bubble'
import PhotoPreview from '../components/PhotoPreview'
import Description from '../components/Description'
import ItemCategorySelector from '../components/Item/ItemCategorySelector'
import KeywordTagSelector from '../components/Item/KeywordTagSelector'
import FeatureTagSelector from '../components/Item/FeatureTagSelector'

import setAttribute from '../actions/setAttribute'
import { getKeywordsNoteForCategory, mergeDefaultFeatures, mergeDefaultKeywordsForCategory } from '../values/ItemCategory'

import styles from './MainContent.scss'

const mapStateToProps = (state, ownProps) => {
  const index = ownProps.match.params.index || 0
  return {
    index: index,
    category: _.result(state.payload, `items[${index}].category`),
    layer: _.result(state.payload, `items[${index}].layer`),
    keywordTagNames: _.result(state.payload, `items[${index}].keywordTagNames`) || [],
    featureTagNames: _.result(state.payload, `items[${index}].featureTagNames`) || [],
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  const idx = ownProps.match.params.index || 0
  return {
    onUpdateItemCategory: (prevCategory, keywords, features) => {
      return (category, layer) => {
        dispatch(setAttribute(`items[${idx}].category`, category))
        dispatch(setAttribute(`items[${idx}].layer`, layer))

        if (_.isEmpty(prevCategory)) {
          dispatch(setAttribute(`items[${idx}].keywordTagNames`, mergeDefaultKeywordsForCategory(category, keywords)))
          dispatch(setAttribute(`items[${idx}].featureTagNames`, mergeDefaultFeatures(features)))
        }
      }
    },
    onUpdateKeywordTags: tags => dispatch(setAttribute(`items[${idx}].keywordTagNames`, tags)),
    onUpdateFeatureTags: tags => dispatch(setAttribute(`items[${idx}].featureTagNames`, tags)),
  }
}

@connectMainContent('items', mapStateToProps, mapDispatchToProps)
export default class Item extends React.Component {
  static propTypes = {
    imageSrc: PropTypes.string.isRequired,
    boundingBox: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number,
      bottom: PropTypes.number,
      right: PropTypes.number
    }),
    contentKey: PropTypes.string,
    index: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    itemCategoryOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    category: PropTypes.string,
    layer: PropTypes.number,
    keywordTagNames: PropTypes.array.isRequired,
    featureTagNames: PropTypes.array.isRequired,
    onCropComplete: PropTypes.func,
    onUpdateItemCategory: PropTypes.func,
    onUpdateKeywordTags: PropTypes.func,
    onUpdateFeatureTags: PropTypes.func,
  }

  render() {
    const errorKeyBase = `${this.props.contentKey}[${this.props.index}]`
    const keywordNoteForCategory = this.props.category ? getKeywordsNoteForCategory(this.props.category) : null
    return (
      <div className={styles['main-content__container']}>
        <div className={styles['main-content__preview']}>
          <PhotoPreview
            errorKeys={{ client: `${errorKeyBase}.boundingBox`, server: `${errorKeyBase}.boundingBox` }}
            errorFormatKey={this.props.contentKey}
            imageSrc={this.props.imageSrc}
            onCropComplete={this.props.onCropComplete}
            boundingBox={this.props.boundingBox} />
        </div>
        <div className={styles['main-content__form-area']}>
          <div className={styles['main-content__form-item']}>
            <ItemCategorySelector
              errorKeys={{ client: `${errorKeyBase}.category`, server: `${errorKeyBase}.category` }}
              index={this.props.index}
              category={this.props.category}
              layer={this.props.layer}
              options={this.props.itemCategoryOptions}
              onUpdate={this.props.onUpdateItemCategory(this.props.category, this.props.keywordTagNames, this.props.featureTagNames)} />
          </div>
          <div className={styles['main-content__form-item']}>
            {keywordNoteForCategory && <Bubble text={keywordNoteForCategory} />}
            <KeywordTagSelector
              errorKeys={{ client: `${errorKeyBase}.keywordTagNames`, server: `${errorKeyBase}.keywordTags` }}
              index={this.props.index}
              tagNames={this.props.keywordTagNames}
              onUpdate={this.props.onUpdateKeywordTags} />
          </div>
          <div className={styles['main-content__form-item']}>
            <FeatureTagSelector
              errorKeys={{ client: `${errorKeyBase}.featureTagNames`, server: `${errorKeyBase}.featureTags` }}
              index={this.props.index}
              tagNames={this.props.featureTagNames}
              onUpdate={this.props.onUpdateFeatureTags} />
            <Description />
          </div>
        </div>
      </div>
    )
  }
}
