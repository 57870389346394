export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE'

export interface DeleteAttributeAction {
  type: typeof DELETE_ATTRIBUTE
  target: string
  validate: boolean
  value: string
}

export default function deleteAttribute(
  target: string, value: string, validate: boolean = true,
): DeleteAttributeAction {
  return { type: DELETE_ATTRIBUTE, target, value, validate }
}
