import React from 'react'
import PropTypes from 'prop-types'

import styles from './profile_preview.module.scss'

const ProfilePreview = ({ displayName, url, linkTarget }) => (
  <div>
    <a className="js-hover" href={url} target={linkTarget}>
      <img alt={displayName} className={styles['image']} src="https://res.cloudinary.com/cubki/image/facebook/c_fill,g_face,h_70,w_70/100007877743908.png" />
      <div className={styles['info']}>
        <p className={styles['name']}>{displayName}</p>
      </div>
    </a>
  </div>
)

ProfilePreview.defaultProps = {
}

ProfilePreview.propTypes = {
  url: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  linkTarget: PropTypes.string,
}

export default ProfilePreview
