export const ADD_ATTRIBUTE = 'ADD_ATTRIBUTE'

export interface AddAttributeAction {
  type: typeof ADD_ATTRIBUTE
  target: string
  validate: boolean
  value: string
}

export default function addAttribute(target: string, value: string, validate: boolean = true): AddAttributeAction {
  return { type: ADD_ATTRIBUTE, target, value, validate }
}
