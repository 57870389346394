import React from 'react'
import PropTypes from 'prop-types'

import SelectableItem from '../../components/SelectableItem'

import styles from './GenreTagSelectBtn.scss'

export default class GenreTagSelectBtn extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
  }

  handleClick = () => {
    if (this.props.onClick) { this.props.onClick(this.props.name, this.props.isActive) }
  }

  render() {
    return (
      <div className={styles['select-btn']} onClick={this.handleClick}>
        <SelectableItem text={this.props.name} isActive={this.props.isActive} />
      </div>
    )
  }
}
