import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Image from '../../image'
import ProfilePreview from './item/profile_preview'
import Description from './item/description'
import styles from './item.module.scss'

const Snap = ({ title, url, picture, user, description, width, linkTarget }) => {
  const height = picture.sp_standard.height / picture.sp_standard.width * width
  return (
    <div className={styles['container']} style={{ width: `${width}px` }}>
      <a className={styles['image-wrapper']} href={url} title={title} target={linkTarget}>
        <Image alt={title} className={classnames('js-hover', styles['image'])} src={picture.sp_standard.url} style={{ width: `${width}px`, height: `${height}px` }} />
      </a>
      <div className={styles['info']}>
        <ProfilePreview url={user.url} displayName={user.display_name} linkTarget={linkTarget} />
        {description == null ? <div /> : <Description content={description.content} title={title} url={url} linkTarget={linkTarget} />}
      </div>
    </div>
  )
}

Snap.defaultProps = {
}

Snap.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  picture: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  description: PropTypes.object,
  width: PropTypes.number.isRequired,
  linkTarget: PropTypes.string,
}

export default Snap
