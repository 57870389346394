import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Wookmark from 'wookmark/wookmark'

const OFFSETS = {
  wide: 20,
  normal: 10,
}

class WaterfallLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = { offset: OFFSETS.wide }
    this.resizeHandler = _.throttle(() => this.updateOffset(), 100)
  }

  render() {
    const { id, children } = this.props
    return <div id={id} style={{ width: '100%', position: 'relative' }}>{children}</div>
  }

  componentDidMount() {
    this.updateLayout()
    window.addEventListener('resize', this.resizeHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
    this.disableLayout()
  }

  componentDidUpdate() { this.updateLayout() }

  get rootElement() { return document.getElementById(this.props.id) }
  get parentWidth() { return this.rootElement.parentNode.clientWidth }

  updateLayout() {
    if (this.wookmark == null) {
      this.initLayout()
    } else if (this.wookmark.offset !== this.state.offset) {
      this.wookmark.updateOptions({ offset: this.state.offset })
    }
    if (this.props.children.length !== this.wookmark.activeItemCount) {
      this.wookmark.initItems()
    }
    this.wookmark.layout(true, () => this.onUpdateLayout())
  }

  initLayout() {
    this.wookmark = new Wookmark(`#${this.props.id}`, {
      container: this.rootElement,
      offset: this.state.offset,
    })
    this.updateOffset()
  }

  disableLayout() {
    if (this.wookmark == null) { return }
    this.wookmark.clear()
    this.wookmark = null
  }

  updateOffset() {
    const newOffset = this.getOffset()
    if (this.state.offset !== newOffset) { this.setState({ offset: newOffset }) }
  }

  getOffset() {
    return this.parentWidth < this.props.itemWidth * 2 + OFFSETS.wide * 3 ? OFFSETS.normal : OFFSETS.wide
  }

  onUpdateLayout() {
    if (this.props.scheduleRedraw) { this.scheduleRedraw() }
    this.props.onUpdateLayout && this.props.onUpdateLayout(this)
  }

  scheduleRedraw() {
    this.validPosition = this.redraw()
    const timer = setInterval(() => {
      if (this.validPosition) {
        clearInterval(timer)
        return
      }
      this.validPosition = this.redraw()
    }, 300)
  }

  redraw() {
    const root = this.rootElement
    const el = root.children[0]
    if (el == null || el.style.left === el.offsetLeft - root.offsetLeft + 'px') { return true }
    // See: https://stackoverflow.com/a/3485654
    root.style.display = 'none'
    root.offsetHeight
    root.style.display = 'block'
    return false
  }
}

WaterfallLayout.defaultProps = {
  scheduleRedraw: false,
}

WaterfallLayout.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  itemWidth: PropTypes.number.isRequired,
  onUpdateLayout: PropTypes.func,
  scheduleRedraw: PropTypes.bool.isRequired,
}

export default WaterfallLayout
