import { findIndex } from 'lodash'
import * as React from 'react'
import Select from 'react-select'
import type { OptionsType } from 'react-select'

import '../ReactSelect.scss'

const i18nScope = 'javascript.components.snaps.editor.main.item.category_selector'

interface Option {
  label: string
  value: string
}

interface ItemCategorySelectInputProps {
  category?: string
  layer?: number
  options: OptionsType<Option>
  onBlur?: () => void
  onUpdate?: (category?: string, layer?: number) => void
}

interface ItemCategorySelectInputState {
  selected: number | null
}

class ItemCategorySelectInput extends React.PureComponent<ItemCategorySelectInputProps, ItemCategorySelectInputState> {
  public constructor(props: ItemCategorySelectInputProps) {
    super(props)
    this.state = { selected: this.findIndex(this.buildValue(this.props.category, this.props.layer)) }
  }

  public buildValue(category?: string, layer?: number) {
    if (category == null) { return null }
    switch (category) {
    case 'tops':
      return `${category}_${layer}`
    default: return category
    }
  }

  public parseValue(value: string | null) {
    if (value == null) { return {} }
    const matched = value.match(/^(.+)_([0-9])$/) || []
    const category = matched[1] || value
    const layer = parseInt(matched[2], 10) || 1
    return { category, layer }
  }

  public componentDidUpdate(prevProps: ItemCategorySelectInputProps) {
    if (prevProps.category !== this.props.category || prevProps.layer !== this.props.layer) {
      this.setState({ selected: this.findIndex(this.buildValue(this.props.category, this.props.layer)) })
    }
  }

  public render() {
    return (
      <Select
        classNamePrefix='item-category-select-input'
        value={this.props.options[this.state.selected]}
        placeholder={I18n.t(`${i18nScope}.placeholder`)}
        options={this.props.options}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
      />
    )
  }

  private findIndex(value: string) {
    const index = findIndex(this.props.options, (option) => option.value === value)
    return index < 0 ? null : index
  }

  private currentOption() {
    return this.state.selected == null ? null : this.props.options[this.state.selected]
  }

  private currentValue() {
    return this.currentOption()?.value
  }

  private handleChange = (value: Option) => {
    this.setState({ selected: this.findIndex(value.value) }, this.update)
  }

  private handleBlur = () => {
    if (this.props.onBlur) { this.props.onBlur() }
  }

  private update() {
    if (this.props.onUpdate) {
      const { category, layer } = this.parseValue(this.currentValue())
      this.props.onUpdate(category, layer)
    }
  }
}

export default ItemCategorySelectInput
