import React from 'react'
import PropTypes from 'prop-types'

import Error from '../Error'
import ItemCategorySelectInput from './ItemCategorySelectInput'

export default class ItemCategorySelector extends React.PureComponent {
  static propTypes = {
    errorKeys: PropTypes.shape({
      client: PropTypes.string,
      server: PropTypes.string,
    }),
    index: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    category: PropTypes.string,
    layer: PropTypes.number,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    onUpdate: PropTypes.func,
  }

  handleUpdate = (category, layer) => {
    this.props.onUpdate(category, layer)
  }

  handleBlur = () => {
    if (this.errorContainer) {
      this.errorContainer.clearServerError()
    }
  }

  render() {
    return (
      <div>
        {this.props.errorKeys ? (
          <Error
            errorRef={ref => this.errorContainer = ref}
            errorKeys={this.props.errorKeys}
            format={I18n.t('javascript.components.snaps.editor.errors.items/category')} />
        ) : null}
        <ItemCategorySelectInput
          key={`category${this.props.index}`}
          category={this.props.category}
          layer={this.props.layer}
          options={this.props.options}
          onBlur={this.handleBlur}
          onUpdate={this.handleUpdate} />
      </div>
    )
  }
}
