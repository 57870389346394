import * as React from 'react'

import styles from './AddItemButton.module.scss'

interface AddItemButtonProps {
  label: string
  onClick?: () => void
}

export default class AddItemButton extends React.PureComponent<AddItemButtonProps> {
  public render() {
    const { label } = this.props
    return (
      <li className={styles['list-item']}>
        <div className={styles['content']} onClick={this.handleClick}>
          {label}
        </div>
      </li>
    )
  }

  private handleClick = () => {
    const { onClick } = this.props
    if (onClick) { onClick() }
  }
}
