import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './SubmitBtn.scss'

export default class SubmitBtn extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func,
  }

  handleClick = () => {
    if (this.props.disabled) { return }
    if (this.props.onSubmit) { this.props.onSubmit() }
  }

  render() {
    const statusClass = this.props.disabled ? 'is-disabled' : null
    return (
      <div onClick={this.handleClick} className={classnames(styles['submit-btn'], statusClass)}>
        <p>{this.props.text}</p>
      </div>
    )
  }
}
