import { compact, get, uniq } from 'lodash'

const defaultKeywordsScope = { scope: 'javascript.components.snaps.editor.values.default_keywords' }

export const DefaultKeywordsForCategory = {
  belt: I18n.t('belt', defaultKeywordsScope),
  earings: I18n.t('earrings', defaultKeywordsScope),
  gloves: I18n.t('gloves', defaultKeywordsScope),
  socks: I18n.t('socks', defaultKeywordsScope),
  suspenders: I18n.t('suspenders', defaultKeywordsScope),
  tie: I18n.t('tie', defaultKeywordsScope),
}

export const mergeDefaultKeywordsForCategory = (category: string, keywords: string[] = []) => {
  return uniq(compact([...keywords, get(DefaultKeywordsForCategory, category)]))
}

const defaultFeaturesScope = { scope: 'javascript.components.snaps.editor.values.default_features' }

export const mergeDefaultFeatures = (features: string[] = []) => {
  return uniq([...features, I18n.t('plain', defaultFeaturesScope)])
}

const noteWordsScope = { scope: 'javascript.components.snaps.editor.values.notes.words' }

const joinedNoteWordsFromKeys = (wordKeys: string[]) => (
  wordKeys.map((wordKey) => I18n.t(wordKey, noteWordsScope)).join(I18n.t('separator', noteWordsScope))
)

const notesScope = { scope: 'javascript.components.snaps.editor.values.notes' }

const dontForgetMessage = (message: string) => I18n.t('dont_forget', notesScope).replace('__message__', message)
const inAdditionToMessage = (message: string, addition: string) => (
  I18n.t('in_addition_to', notesScope).replace('__message__', message).replace('__addition__', addition)
)

const dontForgetInAdditionToMessage = (wordKeys: string[], additionalWordkeys: string[]) => (
  dontForgetMessage(inAdditionToMessage(joinedNoteWordsFromKeys(wordKeys), joinedNoteWordsFromKeys(additionalWordkeys)))
)

export const KeywordNotesForCategory = {
  ['hair_accessory']: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['silhouette', 'motif'],
  ),
  ['leg_wear']: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['shoes_socks_length'],
  ),
  accessory: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['silhouette', 'motif', 'decoration'],
  ),
  bag: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['silhouette', 'handle', 'decoration'],
  ),
  belt: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['silhouette'],
  ),
  bib: dontForgetMessage(joinedNoteWordsFromKeys(['category', 'color', 'pattern'])),
  bottoms: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['silhouette', 'hem_length', 'texture'],
  ),
  dress: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['neckline', 'hem_length', 'sleeve_length', 'silhouette'],
  ),
  earings: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['silhouette', 'motif'],
  ),
  glasses: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern'],
    ['silhouette'],
  ),
  gloves: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['decoration'],
  ),
  handkerchief: dontForgetMessage(joinedNoteWordsFromKeys(['category', 'color', 'pattern'])),
  hat: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['decoration', 'texture', 'silhouette'],
  ),
  mask: dontForgetMessage(joinedNoteWordsFromKeys(['color', 'pattern', 'material'])),
  shoe: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['silhouette', 'heel', 'shoes_length', 'laces_presence'],
  ),
  socks: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['shoes_socks_length'],
  ),
  stole: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['decoration', 'motif', 'texture'],
  ),
  suspenders: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['texture', 'motif'],
  ),
  tie: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['texture', 'motif'],
  ),
  tops: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['neckline', 'hem_length', 'sleeve_length', 'silhouette'],
  ),
  umbrella: dontForgetMessage(joinedNoteWordsFromKeys(['category', 'color', 'pattern'])),
  underwear: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern', 'material'],
    ['silhouette', 'motif'],
  ),
  watch: dontForgetInAdditionToMessage(
    ['category', 'color', 'pattern'],
    ['silhouette'],
  ),
}

export const getKeywordsNoteForCategory = (category: string): string | null => {
  return get(KeywordNotesForCategory, category, null)
}
