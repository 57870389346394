import React from 'react'
import PropTypes from 'prop-types'

import TasteSelectBtn from './TasteSelectBtn'
import Error from '../Error'

import styles from './TasteSelector.scss'

export default class TasteSelector extends React.PureComponent {
  static propTypes = {
    errorKeys: PropTypes.shape({
      client: PropTypes.string,
      server: PropTypes.string,
    }),
    value: PropTypes.string,
    options: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,
  }

  handleClick = (tasteKey) => {
    if (this.errorContainer) {
      this.errorContainer.clearServerError()
    }
    if (this.props.onUpdate) { this.props.onUpdate(tasteKey) }
  }

  render() {
    return (
      <div>
        {this.props.errorKeys ? (
          <Error
            errorRef={ref => this.errorContainer = ref}
            errorKeys={this.props.errorKeys}
            format={I18n.t('javascript.components.snaps.editor.errors.taste_category')} />
        ) : null}
        <ul>
          {Object.keys(this.props.options).map(key => (
            <li key={key} className={styles['select-item']}>
              <TasteSelectBtn
                tasteKey={key}
                text={this.props.options[key]}
                isActive={this.props.value === key}
                onClick={this.handleClick} />
            </li>
          ))}
        </ul>
      </div>
    )
  }
}
