import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import addItemAction from '../actions/addItem'
import removeItemAction from '../actions/removeItem'
import Menu from '../components/Menu'
import type HairstyleItem from '../models/HairstyleItem'
import type Item from '../models/Item'
import type NailItem from '../models/NailItem'
import { ItemGenre } from '../values/ItemGenre'

interface PropsFromStore {
  errors: object
  genreTagNames: string[]
  items: Item[]
  hairstyleItems: HairstyleItem[]
  nailItems: NailItem[]
}

interface DispatchProps {
  onAddItem?: (genre: ItemGenre) => void
  onRemoveItem?: (genre: ItemGenre, index: number) => void
}

// TODO: avoid `any` after refactoring store to TS
const mapStateToProps = (state: any): PropsFromStore => ({
  errors: { ...state.clientErrors, ...state.serverErrors },
  genreTagNames: state.payload.genreTagNames,
  hairstyleItems: state.payload.hairstyleItems,
  items: state.payload.items,
  nailItems: state.payload.nailItems,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onAddItem: (genre: ItemGenre) => dispatch(addItemAction(genre)),
  onRemoveItem: (genre: ItemGenre, index: number) => dispatch(removeItemAction(genre, index)),
})

export default connect<PropsFromStore, DispatchProps>(mapStateToProps, mapDispatchToProps)(Menu)
