import { ofType } from 'redux-observable'
import { NEVER } from 'rxjs'
import { flatMap, tap } from 'rxjs/operators'

import { redirectTo } from '../lib/redirectTo'
import { GO_TO_SNAP_DETAIL } from '../actions/goToSnapDetail'

export default function(action$) {
  return action$.pipe(
    ofType(GO_TO_SNAP_DETAIL),
    tap(action => redirectTo(`/snaps/${action.snapId}`)),
    flatMap(() => NEVER),
  )
}
