import * as React from 'react'

import SelectableItem from '../../components/SelectableItem'

import styles from './FaceStatusSelectBtn.scss'

export interface FaceStatusSelectBtnProps {
  text: string
  isActive: boolean
  onClick?: () => void
}

class FaceStatusSelectBtn extends React.PureComponent<FaceStatusSelectBtnProps> {
  public render() {
    const { text, isActive } = this.props
    return (
      <div className={styles['select-btn']} onClick={this.onClick}>
        <SelectableItem text={text} isActive={isActive} />
      </div>
    )
  }

  private onClick = () => {
    if (this.props.onClick) { this.props.onClick() }
  }
}

export default FaceStatusSelectBtn
