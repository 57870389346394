export const SET_ATTRIBUTE = 'SET_ATTRIBUTE'

export interface SetAttributeAction {
  type: typeof SET_ATTRIBUTE
  target: string
  validate: boolean
  value: any
}

export default function setAttribute(target: string, value: any, validate: boolean = true): SetAttributeAction {
  return { type: SET_ATTRIBUTE, target, value, validate }
}
