import { has, isArray, isEmpty, values } from 'lodash'
import { AjaxError } from 'rxjs/ajax'

class ApiError {
  public messages: string[]
  public messageObject: object
  public cause: AjaxError

  constructor(error: AjaxError) {
    this.cause = error

    let messages: string[] | object
    if (this.hasValidErrorResponse()) {
      messages = this.cause.response.errors.messages
    } else {
      messages = [I18n.t('javascript.adapters.api.response_invalid')]
    }
    if (isArray(messages)) {
      this.messages = messages as string[]
    } else {
      this.messageObject = messages as object
      this.messages = values(this.messageObject)
    }
  }

  private hasValidErrorResponse(): boolean {
    return (this.cause.responseType.toLowerCase() === 'json'
      && has(this.cause, 'response.errors.code')
      && !isEmpty(this.cause.response.errors.code)
      && has(this.cause, 'response.errors.messages')
      && !isEmpty(this.cause.response.errors.messages)
    )
  }
}

export default ApiError
