import React from 'react'
import PropTypes from 'prop-types'

const EmptyContent = ({ id, message }) => <div id={id}><p style={{ textAlign: 'center' }}>{message}</p></div>

EmptyContent.defaultProps = {
}

EmptyContent.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default EmptyContent
