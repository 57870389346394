import classnames from 'classnames'
import * as React from 'react'

import styles from './SelectableItem.scss'

export interface SelectableItemProps {
  text: string
  isActive: boolean
}

class SelectableItem extends React.PureComponent<SelectableItemProps> {
  public render() {
    const { text, isActive } = this.props
    return (
      <div className={classnames(styles['selectable-item'], { ['is-active']: isActive})}>
        <p>{text}</p>
      </div>
    )
  }
}

export default SelectableItem
