import React from 'react'
import _ from 'lodash'

import Poster from '../../../snaps/poster'
import Item from './stylist/item'

const filter = snaps => _.filter(snaps, snap => snap.descriptions.length > 0)

const Stylist = (props) => {
  const linkText = props.linkText
  const WrappedItem = props => <Item {...props} linkText={linkText} />
  return <Poster {...props} id='snaps' linkTarget='_blank' itemWidth={290} item={WrappedItem} filter={filter} scheduleRedraw={true} />
}

export default Stylist
