import React, { useState } from 'react'

import Caption from './Item/Caption'
import IgnoreToggle from './Item/IgnoreToggle'
import Preview from './Item/Preview'

import styles from './Item.module.scss'

export interface ItemProps {
  createdAt: string
  imageUrl: string
  url?: string
  editorUrl?: string
  id: number
  ignored?: boolean
}

const Item = ({ createdAt, editorUrl, imageUrl, id, ignored, url }: ItemProps) => {
  const [currIgnored, setCurrIgnored] = useState(ignored)
  const ignorable = ignored != null && url != null

  return (
    <div className={styles['item']}>
      <Preview disabled={currIgnored !== ignored} linkUrl={editorUrl} imageUrl={imageUrl} />
      <Caption id={id} createdAt={createdAt} />
      {ignorable && <IgnoreToggle onIgnoreChange={setCurrIgnored} ignored={currIgnored} url={url} />}
    </div>
  )
}

export default React.memo(Item)
