import React from 'react'
import PropTypes from 'prop-types'

import SelectableItem from '../../components/SelectableItem'

export default class TasteSelectBtn extends React.PureComponent {
  static propTypes = {
    tasteKey: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
  }

  handleClick = () => {
    if (this.props.onClick) { this.props.onClick(this.props.tasteKey) }
  }

  render() {
    return (
      <div onClick={this.handleClick}>
        <SelectableItem text={this.props.text} isActive={this.props.isActive} />
      </div>
    )
  }
}
