import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { map, switchMap, catchError, withLatestFrom } from 'rxjs/operators'

import { SEND_REQUEST } from '../actions/sendRequest'
import sendPayload from '../lib/sendPayload'
import finishSending from '../actions/finishSending'
import setServerErrors from '../actions/setServerErrors'

export default function(action$, state$) {
  return action$.pipe(
    ofType(SEND_REQUEST),
    withLatestFrom(state$),
    map(([action, state]) => [state.payload, action.url, action.method, action.headers]),
    switchMap(([payload, url, method, headers]) => {
      return sendPayload(url, payload, method, headers).pipe(
        map(data => finishSending(data)),
        catchError(errors => of(setServerErrors(errors))),
      )
    }),
  )
}
