import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'

import Image from '../../image'
import styles from './item.module.scss'

const Ad = ({ label, linkUrl, linkTarget, imageUrl, aspectRatio, width }) => {
  const height = aspectRatio * width
  const contents = [
    <a key='link' className={styles['image-wrapper']} href={linkUrl} title={label} target={linkTarget}>
      <Image className={classnames('js-hover', styles['image'])} src={imageUrl} title={label} style={{ width: `${width}px`, height: `${height}px` }} />
    </a>
  ]
  if (!_.isEmpty(label)) {
    contents.push(
      <div key='label' className={styles['info']}>
        <span className={styles['info__item']}>{label}</span>
      </div>
    )
  }
  return <div className={styles['container']} style={{ width: `${width}px` }}>{contents}</div>
}

Ad.defaultProps = {
}

Ad.propTypes = {
  label: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,
  linkTarget: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  aspectRatio: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

export default Ad
