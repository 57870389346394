import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import BasicInfo from './BasicInfo'
import Tastes from './Tastes'
import Item from './Item'
import Hairstyle from './Hairstyle'
import Nail from './Nail'

import styles from './Main.scss'

@withRouter
@connect()
export default class Main extends React.Component {
  static propTypes = {
    imageSrc: PropTypes.string.isRequired,
    genreTagOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    tasteCategoryOptions: PropTypes.object.isRequired,
    itemCategoryOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    withTitle: PropTypes.bool.isRequired,
    withGenreTagSelector: PropTypes.bool.isRequired,
  }

  render() {
    return (
      <main className={styles['main']}>
        <Switch>
          <Route path='/tastes' exact={true} render={props => <Tastes {...props} imageSrc={this.props.imageSrc} tasteCategoryOptions={this.props.tasteCategoryOptions} />} />
          <Route path='/items/:index' exact={true} render={props => <Item {...props} imageSrc={this.props.imageSrc} itemCategoryOptions={this.props.itemCategoryOptions} />} />
          <Route path='/hairstyles/:index' exact={true} render={props => <Hairstyle {...props} imageSrc={this.props.imageSrc} />} />
          <Route path='/nails/:index' exact={true} render={props => <Nail {...props} imageSrc={this.props.imageSrc} />} />
          <Route render={props => <BasicInfo {...props} imageSrc={this.props.imageSrc} withTitle={this.props.withTitle} withGenreTagSelector={this.props.withGenreTagSelector} genreTagOptions={this.props.genreTagOptions} />} />
        </Switch>
      </main>
    )
  }
}
