import RequestHeaders from '../models/RequestHeaders'

export const PREPARE_REQUEST = 'PREPARE_REQUEST'

export interface PrepareRequestAction {
  type: typeof PREPARE_REQUEST
  url: string
  method: string
  headers: RequestHeaders
}

export default function prepareRequest(
  url: string, method: string, headers: RequestHeaders = {},
): PrepareRequestAction {
  return { type: PREPARE_REQUEST, url, method, headers }
}
