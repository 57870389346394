import { result } from 'lodash'
import { ofType } from 'redux-observable'
import { tap, map, withLatestFrom } from 'rxjs/operators'

import { purgeDraft } from '../lib/draft'
import { FINISH_SENDING } from '../actions/finishSending'
import goToSnapDetail from '../actions/goToSnapDetail'
import stopLoading from '../actions/stopLoading'

export default function(action$, state$) {
  return action$.pipe(
    ofType(FINISH_SENDING),
    map(action => result(action.data, 'snapId')),
    withLatestFrom(state$),
    tap(([_snapId, state]) => { if (state.draftIdentifier) { purgeDraft() } }),
    map(([snapId]) => snapId ? goToSnapDetail(snapId) : stopLoading()),
  )
}
