import classnames from 'classnames'
import React from 'react'

import styles from './Circle.module.scss'

export interface CircleProps {
  className?: string
  color?: string
}

const Circle = ({ className, color }: CircleProps) =>
  <div className={classnames(styles.circle, className)} style={{ borderColor: color }} />

export default Circle
