import React from 'react'
import PropTypes from 'prop-types'
import ReactCrop from 'react-image-crop'

import Error from './Error'
import 'react-image-crop/lib/ReactCrop.scss'

export default class PhotoPreview extends React.PureComponent {
  static propTypes = {
    errorKeys: PropTypes.shape({
      client: PropTypes.string,
      server: PropTypes.string,
    }),
    index: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    imageSrc: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    boundingBox: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number,
      bottom: PropTypes.number,
      right: PropTypes.number
    }),
    onCropComplete: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  handleCropChange = crop => {
    this.setState({ crop: crop })
  }

  handleCropComplete = crop => {
    if (this.props.onCropComplete) {
      const bBox = this.convertToBoundingBox(crop)
      this.props.onCropComplete(bBox)
    }
    if (this.errorContainer) {
      this.errorContainer.clearServerError()
    }
    this.setState({ crop: null })
  }

  convertToCrop(bBox) {
    if (bBox == null ) { return { x: 0, y: 0 } }
    return {
      x: bBox.left * 100,
      y: bBox.top * 100,
      width: (bBox.right - bBox.left) * 100,
      height: (bBox.bottom - bBox.top) * 100,
    }
  }

  convertToBoundingBox(crop) {
    if (crop.width > 0 || crop.height > 0) {
      return {
        top: crop.y / 100,
        left: crop.x / 100,
        bottom: (crop.y + crop.height) / 100,
        right: (crop.x + crop.width) / 100,
      }
    }
    return null
  }

  render() {
    return (
      <div>
        {this.props.errorKeys ? (
          <Error
            errorRef={ref => this.errorContainer = ref}
            errorKeys={this.props.errorKeys}
            format={I18n.t(`javascript.components.snaps.editor.errors.${this.props.errorFormatKey}/bounding_box`)} />
        ) : null}
        <ReactCrop
          src={this.props.imageSrc}
          crop={this.state.crop || this.convertToCrop(this.props.boundingBox)}
          disabled={this.props.disabled}
          onChange={this.handleCropChange}
          onComplete={this.handleCropComplete}
        />
      </div>
    )
  }
}
