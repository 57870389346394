import _ from 'lodash'

import { isItemKey, omitValues } from '../utils'
import { SET_SERVER_ERRORS } from '../actions/setServerErrors'
import { CLEAR_SERVER_ERROR } from '../actions/clearServerError'

const initialState = null

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_SERVER_ERRORS: return action.errors
  case CLEAR_SERVER_ERROR: {
    if (state == null || _.isString(state)) { return state }
    const key = action.key
    const paths = [key]
    if (isItemKey(key)) { paths.push('items') }
    if (key.indexOf('faces') === 0) { paths.push('faces') }
    return omitValues(state, ...paths)
  }
  default: return state
  }
}
