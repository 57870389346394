/* global gtag */
import React from 'react'
import PropTypes from 'prop-types'

import Image from '../../../../image'
import styles from './item.scss'
import character from '../../../../../../assets/images/iframes/riko.png'
import character2x from '../../../../../../assets/images/iframes/riko@2x.png'

const pictureWidth = 145

const sendClickEvent = (label) => {
  gtag('event', 'click', {
    event_category: 'link',
    event_label: label
  })
}

const handlePictureClicks = () => {
  sendClickEvent('Snap Details')
}

const handleSimilarItemClicks = () => {
  sendClickEvent('Similar Stock')
}

const Item = ({ width, data, linkText, linkTarget }) => {
  const picture = data.pictures[0]
  const pictureHeight = picture.sp_standard.height / picture.sp_standard.width * pictureWidth
  return (
    <div className={styles.container} style={{ width: `${width}px` }}>
      <div style={{ height: `${pictureHeight}px`, position: 'relative' }}>
        <a className={`${styles['picture-link']} js-hover`} href={data.url} onClick={handlePictureClicks} target={linkTarget}>
          <Image alt={data.title} src={picture.sp_standard.url} style={{ width: `${pictureWidth}px`, height: `${pictureHeight}px` }} />
        </a>
        <a className={`${styles.link} js-hover`} href={`${data.url}#items`} onClick={handleSimilarItemClicks} target={linkTarget}>{linkText}</a>
        <img className={styles.character} src={character2x} srcSet={`${character2x} 2x, ${character} 1x`} width='36' height='80' />
      </div>
      <div className={styles.description}>{data.descriptions[0].content}</div>
    </div>
  )
}

Item.propTypes = {
  width: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  linkText: PropTypes.string.isRequired,
  linkTarget: PropTypes.string.isRequired,
}

export default Item
