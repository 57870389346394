import { AnyAction } from 'redux'
import ClientErrors from '../models/ClientErrors'

import { SET_CLIENT_ERRORS } from '../actions/setClientErrors'

const initialState: ClientErrors | null = null

export default function(state = initialState, action: AnyAction) {
  switch (action.type) {
  case SET_CLIENT_ERRORS: return action.errors
  default: return state
  }
}
