export const CLEAR_SERVER_ERROR = 'CLEAR_SERVER_ERROR'

export interface ClearServerErrorAction {
  type: typeof CLEAR_SERVER_ERROR
  key: string
}

export default function clearServerError(key: string): ClearServerErrorAction {
  return { type: CLEAR_SERVER_ERROR, key }
}
