import * as React from 'react'

import styles from './message.module.scss'

interface State {
  isOpen: boolean
}

interface Props {
  id: string
  index: number
  text: string
  onRemove?: (id: string) => void
}

class Message extends React.Component<Props, State> {
  private static readonly HEIGHT = 50
  private static readonly MARGIN = 1
  private static readonly CLOSING_DELAY = 5000
  private static readonly REMOVING_DELAY = 600
  private closingTimer: number
  private removingTimer: number

  constructor(props: Props) {
    super(props)
    this.state = { isOpen: false }
    this.handleDiscard = this.handleDiscard.bind(this)
  }

  public componentDidMount() {
    this.startOpeningTimer()
    this.closingTimer = this.startClosingTimer(Message.CLOSING_DELAY)
    this.removingTimer = this.startRemovingTimer(Message.CLOSING_DELAY + Message.REMOVING_DELAY)
  }

  public componentWillUnmount() {
    window.clearTimeout(this.closingTimer)
    window.clearTimeout(this.removingTimer)
  }

  public render() {
    const top: number = this.state.isOpen ? this.props.index * (Message.HEIGHT + Message.MARGIN) : -5 - Message.HEIGHT
    const zIndex: number = 999 - this.props.index
    const styleCalculated = { top, zIndex }
    return (
      <div
        className={styles['message']}
        style={styleCalculated}
        onClick={this.handleDiscard}
      >
        <p className={styles['message__inner']}>
          <span className={styles['message__text']}>
            {this.props.text}
          </span>
        </p>
      </div>
    )
  }

  private startOpeningTimer() {
    window.setTimeout(() => {
      this.setState({ isOpen: true })
    }, 10)
  }

  private startClosingTimer(duration: number): number {
    return window.setTimeout(() => {
      this.setState({ isOpen: false })
    }, duration)
  }

  private startRemovingTimer(duration: number): number {
    return window.setTimeout(() => {
      this.handleRemove()
    }, duration)
  }

  private handleRemove() {
    if (this.props.onRemove) { this.props.onRemove(this.props.id) }
  }

  private handleDiscard() {
    window.clearTimeout(this.closingTimer)
    window.clearTimeout(this.removingTimer)
    this.closingTimer = this.startClosingTimer(0)
    this.removingTimer = this.startRemovingTimer(Message.REMOVING_DELAY)
  }
}

export default Message
