import { gte, isArray, isEmpty, isNumber } from 'lodash'

const requiredText = I18n.t('errors.messages.required')
export const validateRequired = (value: any) => isEmpty(value) ? requiredText : null

const wordCountGteText = (count: number) => (
  I18n.t('javascript.components.snaps.editor.errors.messages.word_count_gte').replace('__count__', `${count}`)
)
export const validateWordCountGte = (count: number) => (
  (value: any) => isArray(value) && gte(value.length, count) ? null : wordCountGteText(count)
)

export const validateRequiredNumber = (value: any) => !isNumber(value) || value === 0 ? requiredText : null
