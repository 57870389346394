import _ from 'lodash'

export function isItemKey(key) {
  return key.indexOf('items') === 0 ||
    key.indexOf('hairstyleItems') === 0 ||
    key.indexOf('nailItems') === 0
}

export function omitValues(object, ...paths) {
  const result = _.omit(object, ...paths)
  return _.isEmpty(result) ? null : result
}

export function omitItemValue(value) {
  return _.map(value, val => {
    const omitted = _.omitBy(val, v => !_.isNumber(v) && _.isEmpty(v))
    return _.isEmpty(omitted) ? null : omitted
  })
}
