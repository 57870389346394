import { ofType } from 'redux-observable'
import { NEVER } from 'rxjs'
import { flatMap, tap } from 'rxjs/operators'

import { STOP_LOADING } from '../actions/stopLoading'

export default function(action$) {
  return action$.pipe(
    ofType(STOP_LOADING),
    tap(() => alert(I18n.t('javascript.components.snaps.editor.success_message'))),
    flatMap(() => NEVER),
  )
}
