import { ItemGenre } from '../values/ItemGenre'

export const REMOVE_ITEM = 'REMOVE_ITEM'

export interface RemoveItemAction {
  itemIndex: number
  itemGenre: ItemGenre
  type: typeof REMOVE_ITEM
}

export default function removeItem(itemGenre: ItemGenre, itemIndex: number): RemoveItemAction {
  return { type: REMOVE_ITEM, itemGenre, itemIndex }
}
