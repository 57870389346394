import React from 'react'

import TagSelector from '../TagSelector'

const FeatureTagSelector = ({ tagNames, errorKeys, onUpdate }) => (
  <TagSelector
    tagType='feature'
    errorFormat={I18n.t('javascript.components.snaps.editor.errors.items/feature_tag_names')}
    captionText={I18n.t('javascript.components.snaps.editor.main.item.feature_tags.caption')}
    tagNames={tagNames}
    errorKeys={errorKeys}
    onUpdate={onUpdate}
  />
)

export default FeatureTagSelector
