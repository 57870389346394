import { ItemGenre } from '../values/ItemGenre'

export const ADD_ITEM = 'ADD_ITEM'

export interface AddItemAction {
  itemGenre: ItemGenre
  type: typeof ADD_ITEM
}

export default function addItem(itemGenre: ItemGenre) {
  return { type: ADD_ITEM, itemGenre }
}
