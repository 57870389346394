import React from 'react'
import PropTypes from 'prop-types'

import Ad from './ad'
import Snap from './snap'

const Item = ({ data, width, linkTarget }) => {
  if (data.type === 'ad') {
    return <Ad label={data.label} linkUrl={data.link_url} imageUrl={data.image_urls.sp_standard} aspectRatio={data.height / data.width} width={width} linkTarget={linkTarget || data.new_tab ? '_blank' : null} />
  } else {
    return <Snap picture={data.pictures[0]} user={data.user} description={data.descriptions[0]} title={data.title} url={data.url} width={width} linkTarget={linkTarget} />
  }
}

Item.defaultProps = {
}

Item.propTypes = {
  data: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  linkTarget: PropTypes.string,
}

export default Item
