import { combineEpics } from 'redux-observable'

import prepareValidation from './prepareValidation'
import validatePayload from './validatePayload'
import buildRequest from './buildRequest'
import sendRequest from './sendRequest'
import moveToRemainingItem from './moveToRemainingItem'
import moveToAddedItem from './moveToAddedItem'
import goToSnapDetail from './goToSnapDetail'
import storeDraft from './storeDraft'
import purgeDraft from './purgeDraft'
import showSuccessMessage from './showSuccessMessage'

export default combineEpics(
  prepareValidation,
  validatePayload,
  buildRequest,
  sendRequest,
  moveToRemainingItem,
  moveToAddedItem,
  goToSnapDetail,
  storeDraft,
  purgeDraft,
  showSuccessMessage,
)
