import React from 'react'

import Item, { ItemProps } from './Item'

import styles from './index.module.scss'

interface ItemData extends ItemProps {
  thumbnailUrl?: string
}

export interface OverviewProps {
  items: ItemData[]
}

const Overview = ({ items }: OverviewProps) => (
  <div className={styles['overview']}>
    {items.map((item) => <Item {...item} imageUrl={item.thumbnailUrl || item.imageUrl} key={item.id} />)}
  </div>
)

export default Overview
