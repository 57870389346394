import React, { useContext } from 'react'
import { ReactReduxContext } from 'react-redux'

import ErrorMessageList from '../containers/ErrorMessageList'

const Error = ({ errorRef, errorKeys, format }) => {
  const { store } = useContext(ReactReduxContext) || {}
  if (store == null) { return null }
  return (
    <ErrorMessageList
      ref={errorRef}
      errorKeys={errorKeys}
      format={format} />
  )
}

export default Error
