import * as React from 'react'

import styles from './Caption.module.scss'

interface CaptionProps {
  note?: string
  text: string
}

const Caption = ({ note, text }: CaptionProps) => (
  <div className={styles['caption']}>
    <span>{text}</span>
    {note && <span className={styles['note']}>{note}</span>}
  </div>
)

export default React.memo(Caption)
