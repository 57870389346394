import React, { useCallback, useMemo, useState } from 'react'

import api, { ApiError } from '../../../adapters/api'

import styles from './IgnoreToggle.module.scss'

interface IgnoreToggleProps {
  url: string
  ignored: boolean
  onIgnoreChange?: (ignored: boolean) => void
}

const i18nScope = 'javascript.components.tag_overview.ignore_toggle'
const useToggleIgnoreApi = (
  url: string, ignored: boolean, resultCallback?: (ignored: boolean) => void,
  ): [boolean, () => void] => {
  const [isLoading, setIsLoading] = useState(false)

  const callback = useCallback(() => {
    if (isLoading) {
      return
    }
    setIsLoading(true)

    api(`${url}.json`, {}, { camelcase: false })
      .patch({ ignored: !ignored })
      .subscribe(
        (response) => {
          if (resultCallback) { resultCallback(response.response.ignored) }
        },
        (error: ApiError) => {
          error.messages.forEach((message: string) => { CBK.alert(message) })
          setIsLoading(false)
        },
        () => {
          setIsLoading(false)
        },
      )
  }, [url, isLoading, setIsLoading, ignored, resultCallback])

  return [isLoading, callback]
}

const IgnoreToggle = ({ ignored, onIgnoreChange, url }: IgnoreToggleProps) => {
  const [isLoading, ignoreApiCallback] = useToggleIgnoreApi(url, ignored, onIgnoreChange)
  const caption = useMemo(() => {
    if (isLoading) {
      return I18n.t(`${i18nScope}.loading`)
    }
    return ignored ? I18n.t(`${i18nScope}.unignore`) : I18n.t(`${i18nScope}.ignore`)
  }, [ignored, isLoading])

  return (
    <div className={styles['toggle']} onClick={ignoreApiCallback}>
      {caption}
    </div>
  )
}

export default React.memo(IgnoreToggle)
