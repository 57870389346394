import payload from './payload'
import clientErrors from './clientErrors'
import serverErrors from './serverErrors'
import isLoading from './isLoading'
import canSend from './canSend'
import draftIdentifier from './draftIdentifier'

export default {
  payload,
  clientErrors,
  serverErrors,
  isLoading,
  canSend,
  draftIdentifier,
}
