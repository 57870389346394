import { isEmpty } from 'lodash'
import React from 'react'

import styles from './StockPreview.module.scss'

const buildSrc = (keyword: string) => `/stock?keyword=${encodeURIComponent(keyword)}&from=snap-editor`

export interface StockPreviewProps {
  keyword: string
}

const StockPreview = ({ keyword }: StockPreviewProps) => {
  const iframe = isEmpty(keyword) ?  null :
    <iframe key={keyword} className={styles['stock-preview']} src={buildSrc(keyword)} />

  return <div>{iframe}</div>
}

export default StockPreview
