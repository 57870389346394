import ClientErrors from '../models/ClientErrors'

export const SET_CLIENT_ERRORS = 'SET_CLIENT_ERRORS'

export interface SetClientErrorsAction {
  errors: ClientErrors
  type: typeof SET_CLIENT_ERRORS
}

export default function setClientErrors(errors: ClientErrors | null): SetClientErrorsAction {
  return { type: SET_CLIENT_ERRORS, errors }
}
