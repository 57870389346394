import classnames from 'classnames'
import React from 'react'

import Circle from './Loading/Circle'

import styles from './Loading.module.scss'

export interface LoadingProps {
  className?: string
  innerClassName?: string
  color?: string
}

const Loading = ({ className, innerClassName, color }: LoadingProps) => (
  <div className={classnames(styles.container, className)}>
    <Circle className={classnames(styles.circle, innerClassName)} color={color} />
  </div>
)

export default Loading
