import _ from 'lodash'

import { isItemKey, omitItemValue } from '../utils'
import { SET_ATTRIBUTE } from '../actions/setAttribute'
import { ADD_ATTRIBUTE } from '../actions/addAttribute'
import { ADD_ITEM } from '../actions/addItem'
import { DELETE_ATTRIBUTE } from '../actions/deleteAttribute'
import { REMOVE_ITEM } from '../actions/removeItem'

const initialState = {
  blogPhotoId: null,
  faces: [{}],
  items: [],
  hairstyleItems: [],
  nailItems: [],
  genreTagNames: ['コーデ'],
  sceneTagNames: [],
  title: '',
  tasteCategory: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_ATTRIBUTE:
    return _.chain(state).cloneDeep().set(action.target, action.value).mapValues((value, key) => {
      if (!isItemKey(action.target) || !isItemKey(key)) { return value }
      return omitItemValue(value)
    }).value()
  case ADD_ATTRIBUTE: {
    const newValue = _.union(_.result(state, action.target), [action.value])
    return _.chain(state).cloneDeep().set(action.target, newValue).value()
  }
  case DELETE_ATTRIBUTE: {
    const newValue = _.without(_.result(state, action.target), action.value)
    return _.chain(state).cloneDeep().set(action.target, newValue).value()
  }
  case ADD_ITEM: {
    const newValue = _.concat(_.result(state, action.itemGenre), null)
    return _.chain(state).clone().set(action.itemGenre, newValue).value()
  }
  case REMOVE_ITEM: {
    const newValue = _.chain(state).result(action.itemGenre).filter((_, idx) => idx != action.itemIndex).value()
    return _.chain(state).clone().set(action.itemGenre, newValue).value()
  }
  default: return state
  }
}
