import { includes, isEmpty, map, pickBy, some, times } from 'lodash'
import * as React from 'react'

import HairstyleItemProp from '../models/HairstyleItem'
import ItemProp from '../models/Item'
import NailItemProp from '../models/NailItem'
import { ItemGenre } from '../values/ItemGenre'
import AddItemButton from './Menu/AddItemButton'
import Item from './Menu/Item'

import styles from './Menu.module.scss'

export interface MenuProps {
  errors: object
  genreTagNames: string[]
  items: ItemProp[]
  hairstyleItems: HairstyleItemProp[]
  nailItems: NailItemProp[]
  onAddItem?: (genre: ItemGenre) => void
  onRemoveItem?: (genre: ItemGenre, index: number) => void
}

const i18nScope = 'javascript.components.snaps.editor.menu'

export default class Menu extends React.Component<MenuProps> {
  private readonly addItemCallbacks: { [genre in ItemGenre]: () => void }
  private readonly removeItemCallbacks: { [genre in ItemGenre]: Array<() => void> }
  private readonly MAX_ITEMS = {
    [ItemGenre.ITEMS]: 20,
    [ItemGenre.HAIRSTYLES]: 1,
    [ItemGenre.NAILS]: 1,
  }

  public constructor(props: MenuProps) {
    super(props)

    this.addItemCallbacks = {
      [ItemGenre.ITEMS]: this.handleAddItem.bind(this, ItemGenre.ITEMS),
      [ItemGenre.HAIRSTYLES]: this.handleAddItem.bind(this, ItemGenre.HAIRSTYLES),
      [ItemGenre.NAILS]: this.handleAddItem.bind(this, ItemGenre.NAILS),
    }

    this.removeItemCallbacks = {
      [ItemGenre.ITEMS]: this.removeItemCallbacksForGenre(ItemGenre.ITEMS),
      [ItemGenre.NAILS]: this.removeItemCallbacksForGenre(ItemGenre.NAILS),
      [ItemGenre.HAIRSTYLES]: this.removeItemCallbacksForGenre(ItemGenre.HAIRSTYLES),
    }
  }

  public render() {
    return (
      <nav>
        <ul className={styles['list']}>
          {this.renderBasicInfoItem()}
          {this.renderTastesItem()}
        </ul>
        {this.renderItemMenu()}
        {this.renderHairStyleMenu()}
        {this.renderNailMenu()}
      </nav>
    )
  }

  private renderBasicInfoItem() {
    const hasError = !isEmpty(pickBy(this.props.errors, (_, key) => {
      return key.indexOf('faces') === 0 || key === 'genreTagNames' || key === 'genreTags' ||
        key === 'title' || key === 'sceneTagNames' || key === 'sceneTags'
    }))
    return (
      <Item linkTo='/' error={hasError} label={I18n.t(`${i18nScope}.basic_information`)} />
    )
  }

  private renderTastesItem() {
    const hasError = !isEmpty(pickBy(this.props.errors, (_, key) => key === 'tasteCategory'))
    return (
      <Item linkTo='/tastes' error={hasError} label={I18n.t(`${i18nScope}.tastes`)} />
    )
  }

  private renderItemMenu() {
    if (!some(this.props.genreTagNames, (genre) => genre === 'コーデ' || genre === 'アイテム')) { return null }
    const addItemButtonProps = {
      label: I18n.t(`${i18nScope}.add_item`),
      onClick: this.addItemCallbacks[ItemGenre.ITEMS],
    }
    return (
      <ul className={styles['list']}>
        {map(this.props.items, (_, idx) => this.renderItem(idx))}
        {this.props.items.length < this.MAX_ITEMS[ItemGenre.ITEMS] && <AddItemButton {...addItemButtonProps} />}
      </ul>
    )
  }

  private renderItem(idx: number) {
    const route = `/items/${idx}`
    return (
      <Item
        key={route}
        linkTo={route}
        empty={this.props.items[idx] == null}
        error={!isEmpty(pickBy(this.props.errors, (_, key) => key.indexOf(`items[${idx}]`) === 0))}
        label={I18n.t(`${i18nScope}.item`, { index: idx + 1 })}
        onRemove={this.removeItemCallbacks[ItemGenre.ITEMS][idx]}
      />
    )
  }

  private renderHairStyleMenu() {
    const { genreTagNames, hairstyleItems } = this.props
    if (!includes(genreTagNames, 'ヘアスタイル')) { return null }
    const addItemButtonProps = {
      label: I18n.t(`${i18nScope}.add_hairstyle`),
      onClick: this.addItemCallbacks[ItemGenre.HAIRSTYLES],
    }
    return (
      <ul className={styles['list']}>
        {map(hairstyleItems, (_, idx) => this.renderHairstyleItem(idx))}
        {hairstyleItems.length < this.MAX_ITEMS[ItemGenre.HAIRSTYLES] && <AddItemButton {...addItemButtonProps} />}
      </ul>
    )
  }

  private renderHairstyleItem(idx: number) {
    const route = `/hairstyles/${idx}`
    return (
      <Item
        key={route}
        linkTo={route}
        empty={this.props.hairstyleItems[idx] == null}
        error={!isEmpty(pickBy(this.props.errors, (_, key) => key.indexOf(`hairstyleItems[${idx}]`) === 0))}
        label={I18n.t(`${i18nScope}.hairstyle`, { index: idx + 1 })}
        onRemove={this.removeItemCallbacks[ItemGenre.HAIRSTYLES][idx]}
      />
    )
  }

  private renderNailMenu() {
    if (!includes(this.props.genreTagNames, 'ネイル')) { return null }
    const addItemButtonProps = {
      label: I18n.t(`${i18nScope}.add_nail`),
      onClick: this.addItemCallbacks[ItemGenre.NAILS],
    }
    return (
      <ul className={styles['list']}>
        {map(this.props.nailItems, (_, idx) => this.renderNailItem(idx))}
        {this.props.nailItems.length < this.MAX_ITEMS[ItemGenre.NAILS] && <AddItemButton {...addItemButtonProps} />}
      </ul>
    )
  }

  private renderNailItem(idx: number) {
    const route = `/nails/${idx}`
    return (
      <Item
        key={route}
        linkTo={route}
        empty={this.props.nailItems[idx] == null}
        error={!isEmpty(pickBy(this.props.errors, (_, key) => key.indexOf(`nailItems[${idx}]`) === 0))}
        label={I18n.t(`${i18nScope}.nail`, { index: idx + 1 })}
        onRemove={this.removeItemCallbacks[ItemGenre.NAILS][idx]}
      />
    )
  }

  private handleAddItem(genre: ItemGenre) {
    const { onAddItem } = this.props
    if (onAddItem) { onAddItem(genre) }
  }

  private handleRemoveItem(genre: ItemGenre, idx: number) {
    const { onRemoveItem } = this.props
    if (onRemoveItem) { onRemoveItem(genre, idx) }
  }

  private removeItemCallbacksForGenre(genre: ItemGenre) {
    return times(this.MAX_ITEMS[genre], (idx) => this.handleRemoveItem.bind(this, genre, idx))
  }
}
