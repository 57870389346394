import _ from 'lodash'
import type { Action } from 'redux'
import { ofType } from 'redux-observable'
import type { ActionsObservable, StateObservable } from 'redux-observable'
import { concat, of } from 'rxjs'
import type { Observable } from 'rxjs'
import { flatMap, map, tap, withLatestFrom } from 'rxjs/operators'

import { PREPARE_REQUEST } from '../actions/prepareRequest'
import type { PrepareRequestAction } from '../actions/prepareRequest'
import sendRequest from '../actions/sendRequest'
import setClientErrors from '../actions/setClientErrors'
import startSending from '../actions/startSending'
import { validateAll } from '../lib/validation'

export default function(action$: ActionsObservable<Action>, state$: StateObservable<any>): Observable<Action> {
  return action$.pipe(
    ofType<PrepareRequestAction>(PREPARE_REQUEST),
    withLatestFrom(state$),
    map(([action, state]) => {
      const errors = _.omitBy(validateAll(state.payload), _.isEmpty)
      return { errors, url: action.url, method: action.method, headers: action.headers }
    }),
    flatMap(({ errors, url, method, headers }) => {
      const loadingStream = of(startSending())
      let nextAction
      if (_.isEmpty(errors)) {
        nextAction = of(sendRequest(url, method, headers))
      } else {
        nextAction = of(setClientErrors(errors)).pipe(
          tap(() => alert(I18n.t('javascript.components.snaps.editor.failed_message'))),
        )
      }
      return concat(loadingStream, nextAction)
    }),
  )
}
