export enum ItemGenre {
  ITEMS = 'items',
  HAIRSTYLES = 'hairstyleItems',
  NAILS = 'nailItems',
}

export const RoutePathForItemGenre = {
  [ItemGenre.ITEMS]: '/items',
  [ItemGenre.HAIRSTYLES]: '/hairstyles',
  [ItemGenre.NAILS]: '/nails',
}
