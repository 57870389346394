import { Action } from 'redux'
import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { merge, Observable } from 'rxjs'
import { filter, map, withLatestFrom } from 'rxjs/operators'

import { ADD_ATTRIBUTE } from '../actions/addAttribute'
import { DELETE_ATTRIBUTE } from '../actions/deleteAttribute'
import { EditItemAction } from '../actions/editItem'
import { EditItemAttributeAction } from '../actions/editItemAttribute'
import performValidation, { PerformValidationAction } from '../actions/performValidation'
import { REMOVE_ITEM } from '../actions/removeItem'
import { SET_ATTRIBUTE } from '../actions/setAttribute'

const ofItemActionType = () =>
  (source: Observable<Action>): Observable<EditItemAction> => source.pipe(ofType<EditItemAction>(REMOVE_ITEM))

const ofItemAttributeActionType = () =>
  (source: Observable<Action>): Observable<EditItemAttributeAction> => source.pipe(
    ofType<EditItemAttributeAction>(SET_ATTRIBUTE, ADD_ATTRIBUTE, DELETE_ATTRIBUTE),
  )

const mapIntoPerformValidation = () =>
  (source: Observable<EditItemAction>): Observable<PerformValidationAction> => source.pipe(
    map((_) => performValidation()),
  )

const mapIntoPerformPartialValidation = (state$: StateObservable<any>) =>
  (source: Observable<EditItemAttributeAction>): Observable<PerformValidationAction> => source.pipe(
    withLatestFrom(state$),
    map(([_, state]) => performValidation(state.router.location.pathname)),
  )

// TODO: avoid `any` after migrating store / actions to TS
export default function(
  action$: ActionsObservable<Action>, state$: StateObservable<any>,
): Observable<PerformValidationAction> {
  return merge(
    action$.pipe(ofItemActionType(), mapIntoPerformValidation()),
    action$.pipe(
      ofItemAttributeActionType(),
      filter((action) => action.validate === true),
      mapIntoPerformPartialValidation(state$)),
  )
}
