import React from 'react'

import TagSelector from '../TagSelector'

const SceneTagSelector = ({ tagNames, errorKeys, onUpdate }) => (
  <TagSelector
    tagType='scene'
    errorFormat={I18n.t('javascript.components.snaps.editor.errors.scene_tag_names')}
    captionText={I18n.t('javascript.components.snaps.editor.main.basic_information.scene_tags')}
    captionNote={I18n.t('javascript.components.snaps.editor.notes.word_count_gte').replace('__count__', '2')}
    tagNames={tagNames}
    errorKeys={errorKeys}
    onUpdate={onUpdate}
  />
)

export default SceneTagSelector
