import { debounce, isEqual } from 'lodash'
import * as React from 'react'
import AsyncCreatable from 'react-select/async-creatable'
import 'whatwg-fetch'

import './ReactSelect.scss'

const i18nScope = 'javascript.components.snaps.editor.autocompletable_input'

export interface AutocompletableInputProps {
  multi: boolean
  tagNames: string[]
  tagType: string
  onBlur?: () => void
  onUpdate?: (tagNames: string[]) => void
}

interface AutocompletableInputState {
  tags?: Tag[]
}

const nameKey = 'name_ja'

export interface Tag {
  label?: string
  [nameKey]: string
}

class AutocompletableInput extends React.PureComponent<AutocompletableInputProps, AutocompletableInputState> {
  private readonly loadOptions = debounce((input, callback) => {
    if (!this.isValid(input)) { return callback([]) }
    fetch(this.getOptionsUrl(input), { credentials: 'include' })
      .then((resp) => resp.json())
      .then((tags) => callback(tags))
  }, 250)

  public constructor(props: AutocompletableInputProps) {
    super(props)
    this.state = { tags: this.mapNamesToTags(this.props.tagNames) }
  }

  public isValid(input: string) {
    return input != null && input.trim().length > 0
  }

  public getOptionsUrl(input: string) {
    return `/tags/${this.props.tagType}/start_with.json?q=${encodeURIComponent(input)}`
  }

  public componentDidUpdate(prevProps: AutocompletableInputProps) {
    if (!isEqual(prevProps.tagNames, this.props.tagNames)) {
      this.setState({ tags: this.mapNamesToTags(this.props.tagNames) })
    }
  }

  public render() {
    const placeholder = I18n.t(`${i18nScope}.placeholder`)
    return (
      <AsyncCreatable
        classNamePrefix='autocompletable-input'
        components={{ DropdownIndicator: null }}
        isMulti={this.props.multi}
        value={this.state.tags}
        placeholder={placeholder}
        getNewOptionData={this.getNewOptionData}
        createOptionPosition='first'
        noOptionsMessage={this.noOptionsMessage}
        formatCreateLabel={this.formatCreateLabel}
        getOptionLabel={this.getOptionLabel}
        getOptionValue={this.getOptionValue}
        loadOptions={this.loadOptions}
        filterOption={this.filterOption}
        isValidNewOption={this.isValidNewOption}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
      />
    )
  }

  private handleChange = (tags: Tag[]) => {
    this.setState({ tags }, this.update)
  }

  private handleBlur = () => {
    if (this.props.onBlur) { this.props.onBlur() }
  }

  private update() {
    if (this.props.onUpdate) { this.props.onUpdate(this.mapTagsToNames(this.state.tags)) }
  }

  private filterOption = () => {
    return true
  }

  private getOptionLabel = (option: Tag) => option.label || option[nameKey]

  private getOptionValue = (option: Tag) => option[nameKey]

  private getNewOptionData = (inputValue: string, optionLabel: string) => {
    return { label: optionLabel, [nameKey]: inputValue }
  }

  private isValidNewOption = (inputValue: string, value: Tag[], options: Tag[]) => {
    return this.isValid(inputValue) && options.every((tag) => tag[nameKey] !== inputValue)
  }

  private formatCreateLabel = (label: string) => {
    return I18n.t(`${i18nScope}.promptTextCreator`, { tag: label })
  }

  private noOptionsMessage = (): string | null => null

  private mapTagsToNames(tags: Tag[]) {
    return (tags || []).map((tag) => tag.name_ja)
  }

  private mapNamesToTags(tagNames: string[]) {
    return (tagNames || []).map((name) => {
      return { name_ja: name }
    })
  }
}

export default AutocompletableInput
