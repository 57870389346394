export default class GlueySession {
  public static refreshCurrent(): GlueySession | null {
    if (gon.glueySessionId) {
      sessionStorage.setItem(GlueySession.ID_STORAGE_KEY, gon.glueySessionId)
    }

    const currentId = sessionStorage.getItem(GlueySession.ID_STORAGE_KEY)
    if (!currentId) {
      return null
    }

    return new GlueySession(currentId)
  }

  private static readonly ID_STORAGE_KEY = 'CBK-GLUEY-SESSION-ID'

  public readonly id: string

  constructor(id: string) {
    this.id = id
  }
}
