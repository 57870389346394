import * as _ from 'lodash'
import * as React from 'react'

import styles from './alert.module.scss'
import Message from './alert/message'

interface State {
  messages: IdentifiableText[]
}

interface IdentifiableText {
  text: string
  id: string
}

class Alert extends React.Component<{}, State> {
  private static readonly BACKUP_ALERT_HANDLER = CBK.alert

  constructor(props: {}) {
    super(props)
    this.state = { messages: [] }
    this.handleRemove = this.handleRemove.bind(this)
  }

  public componentDidMount() {
    CBK.alert = this.addMessage.bind(this)
    CBK._alertMessages.forEach((message: string) => {
      this.addMessage(message)
    })
    CBK._alertMessages = []
  }

  public componentWillUnmount() {
    CBK.alert = Alert.BACKUP_ALERT_HANDLER
  }

  public render() {
    if (this.state.messages.length === 0) {
      return null
    }

    const messages = this.state.messages.map((message, index) => (
      <Message
        id={message.id}
        index={index}
        key={message.id}
        text={message.text}
        onRemove={this.handleRemove}
      />),
    )

    return (
      <div className={styles['alert']}>
        {messages}
      </div>
    )
  }

  public addMessage(text: string) {
    if (!text || !text.trim()) {
      return
    }

    const newMessage: IdentifiableText = {
      id: _.uniqueId(),
      text,
    }

    this.setState((prevState) => {
      return { messages: [newMessage].concat(prevState.messages) }
    })
  }

  private handleRemove(id: string) {
    this.setState((prevState) => {
      const remainingMessages = prevState.messages.filter((currentMessage) => {
        return currentMessage.id !== id
      })

      return { messages: remainingMessages }
    })
  }
}

export default Alert
