import React from 'react'
import PropTypes from 'prop-types'

import Caption from '../Caption'
import Error from '../Error'

import styles from './TitleInput.scss'

export default class TitleInput extends React.PureComponent {
  static propTypes = {
    errorKeys: PropTypes.shape({
      client: PropTypes.string,
      server: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
    onUpdate: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = { title: this.props.title || '' }
  }

  handleBlur = () => {
    if (this.errorContainer) {
      this.errorContainer.clearServerError()
    }
    this.update()
  }

  handleChange = e => {
    this.setState({ title: e.target.value })
  }

  handleSubmit = e => {
    if (e.which !== 13) { return }
    this.update()
  }

  update() {
    if (this.props.onUpdate) { this.props.onUpdate(this.state.title.trim()) }
  }

  render() {
    return (
      <div>
        {this.props.errorKeys ? (
          <Error
            errorRef={ref => this.errorContainer = ref}
            errorKeys={this.props.errorKeys}
            format={I18n.t('javascript.components.snaps.editor.errors.title')} />
        ) : null}
        <Caption text={I18n.t('javascript.components.snaps.editor.main.basic_information.title')} />
        <input
          className={styles['text-input']}
          type='text'
          value={this.state.title}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onKeyDown={this.handleSubmit} />
      </div>
    )
  }
}
