import React from 'react'
import PropTypes from 'prop-types'

import connectMainContent from './connectMainContent'
import PhotoPreview from '../components/PhotoPreview'
import Description from '../components/Description'
import FaceStatusSelector from '../components/BasicInfo/FaceStatusSelector'
import GenreTagSelector from '../components/BasicInfo/GenreTagSelector'
import TitleInput from '../components/BasicInfo/TitleInput'
import SceneTagSelector from '../components/BasicInfo/SceneTagSelector'
import Error from '../components/Error'

import setAttribute from '../actions/setAttribute'
import addAttribute from '../actions/addAttribute'
import deleteAttribute from '../actions/deleteAttribute'

import styles from './MainContent.scss'

const mapStateToProps = state => {
  return {
    title: state.payload.title,
    faces: state.payload.faces,
    genreTagNames: state.payload.genreTagNames,
    sceneTagNames: state.payload.sceneTagNames,
    withFace: state.payload.faces[0] != null,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onUpdateFaceStatus: status => dispatch(setAttribute('faces', status === false ? [] : [{}])),
    onUpdateTitle: title => dispatch(setAttribute('title', title)),
    onSelectGenreTag: tagName => dispatch(addAttribute('genreTagNames', tagName)),
    onDeselectGenreTag: tagName => dispatch(deleteAttribute('genreTagNames', tagName)),
    onUpdateSceneTags: tags => dispatch(setAttribute('sceneTagNames', tags)),
  }
}

@connectMainContent('faces', mapStateToProps, mapDispatchToProps)
export default class BasicInfo extends React.Component {
  static propTypes = {
    imageSrc: PropTypes.string.isRequired,
    genreTagOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    boundingBox: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number,
      bottom: PropTypes.number,
      right: PropTypes.number
    }),
    contentKey: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    faces: PropTypes.arrayOf(PropTypes.object).isRequired,
    genreTagNames: PropTypes.array.isRequired,
    sceneTagNames: PropTypes.array.isRequired,

    onCropComplete: PropTypes.func,
    onUpdateFaceStatus: PropTypes.func,
    onUpdateTitle: PropTypes.func,
    onSelectGenreTag: PropTypes.func,
    onDeselectGenreTag: PropTypes.func,
    onUpdateSceneTags: PropTypes.func,
  }

  render() {
    return (
      <div className={styles['main-content__container']}>
        <Error
          errorKeys={{ client: 'faces', server: 'faces' }}
          format={I18n.t('javascript.components.snaps.editor.errors.faces')} />
        <div className={styles['main-content__preview']}>
          <PhotoPreview
            errorKeys={{ client: `${this.props.contentKey}[0].boundingBox`, server: `${this.props.contentKey}[0].boundingBox` }}
            errorFormatKey={this.props.contentKey}
            imageSrc={this.props.imageSrc}
            disabled={!this.props.withFace}
            onCropComplete={this.props.onCropComplete}
            boundingBox={this.props.boundingBox} />
        </div>
        <div className={styles['main-content__form-area']}>
          <div className={styles['main-content__form-item']}>
            <FaceStatusSelector
              errorKeys={{ client: `${this.props.contentKey}[0].boundingBox`, server: `${this.props.contentKey}[0].boundingBox` }}
              currentStatus={this.props.withFace}
              onUpdate={this.props.onUpdateFaceStatus} />
            {this.props.withFace ? <Description /> : null}
          </div>
          {this.props.withGenreTagSelector ? (
            <div className={styles['main-content__form-item']}>
              <GenreTagSelector
                errorKeys={{ client: 'genreTagNames', server: 'genreTags' }}
                tagNames={this.props.genreTagNames}
                options={this.props.genreTagOptions}
                onSelect={this.props.onSelectGenreTag}
                onDeselect={this.props.onDeselectGenreTag} />
            </div>
          ) : null}
          {this.props.withTitle ? (
            <div className={styles['main-content__form-item']}>
              <TitleInput
                errorKeys={{ client: 'title', server: 'title' }}
                title={this.props.title}
                onUpdate={this.props.onUpdateTitle} />
            </div>
          ) : null}
          <div className={styles['main-content__form-item']}>
            <SceneTagSelector
              errorKeys={{ client: 'sceneTagNames', server: 'sceneTags' }}
              tagNames={this.props.sceneTagNames}
              onUpdate={this.props.onUpdateSceneTags} />
          </div>
        </div>
      </div>
    )
  }
}
