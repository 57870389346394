import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import _ from 'lodash'
import humps from 'humps'

import api from '../../../../adapters/api'

// FIXME: Refactor ane make this testable
export default function(url, payload, method = 'POST', headers = {}) {
  const itemKeys = requestedItemKeys(payload) // Store current item keys
  return api(url, {}, { camelcase: false }).send(method, buildBody(payload), headers).pipe(
    map(data => formatResponse(data)),
    catchError(errors => throwError(formatErrorResponse(errors, itemKeys))),
  )
}

const buildBody = payload => {
  return JSON.stringify(formatPayload(payload))
}

const formatPayload = payload => {
  return humps.decamelizeKeys(convertItemKeys(payload))
}

const convertItemKeys = payload => {
  const converted = _.mapValues(
    _.omit({ ...payload, items: filteredPayloadItems(payload)}, 'hairstyleItems', 'nailItems'),
    v => _.isArray(v) ? _.compact(v) : v
  )
  return {
    ...converted,
    // To avoid server-side validation error for layer
    items: [..._.map(converted.items, item => ({ layer: 1, ...item }))]
  }
}

const filteredPayloadItems = payload => {
  const items = []
  const genreTagNames = payload.genreTagNames
  if (_.some(genreTagNames, genre => genre === 'コーデ' || genre === 'アイテム')) { items.push(...payload.items) }
  if (_.includes(genreTagNames, 'ヘアスタイル')) { items.push(...payload.hairstyleItems) }
  if (_.includes(genreTagNames, 'ネイル')) { items.push(...payload.nailItems) }
  return items
}

const requestedItemKeys = payload => {
  return _.chain(['items', 'hairstyleItems', 'nailItems']).flatMap(key => _.map(payload[key], (item, idx) => item ? `${key}[${idx}]` : null)).compact().value()
}

const formatResponse = data => {
  return { snapId: _.result(data, 'response.id') }
}

const formatErrorResponse = (errors, itemKeys) => {
  if (errors.cause.status === 422 && errors.messageObject != null) {
    return _.omitBy(
      humps.camelizeKeys(revertItemKeys(errors.messageObject, itemKeys)),
      (_, key) => key.includes('tagIds') // Exclude unused error keys
    )
  } else {
    const payload = { status: errors.cause.status }
    const messages = errors.messageObject || errors.messages
    if (messages != null) { payload.messages = messages }
    return JSON.stringify(payload)
  }
}

const revertItemKeys = (errors, itemKeys) => {
  return _.reduce(errors, (result, val, key) => {
    const revertedKey = key.replace(/^items\[(\d+)\]/, (_, index) => itemKeys[index])
    return _.tap(result, res => res[revertedKey] = _.uniq(val))
  }, {})
}
