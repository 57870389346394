import { compact, isEmpty, omitBy } from 'lodash'
import { Action } from 'redux'
import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, withLatestFrom } from 'rxjs/operators'

import { PERFORM_VALIDATION, PerformValidationAction } from '../actions/performValidation'
import setClientErrors from '../actions/setClientErrors'
import { validate, validateAll } from '../lib/validation'

// TODO: avoid `any` after migrating store to TS
export default function(action$: ActionsObservable<Action>, state$: StateObservable<any>): Observable<Action> {
  return action$.pipe(
    ofType<PerformValidationAction>(PERFORM_VALIDATION),
    withLatestFrom(state$),
    map(([action, state]) => {
      if (!action.targetPath) {
        return setClientErrors(omitBy(validateAll(state.payload), isEmpty))
      }

      const [scope, idxStr] = compact(action.targetPath.split('/'))
      const idx = idxStr ? parseInt(idxStr, 10) : undefined
      const nextErrors = omitBy({ ...state.clientErrors, ...validate(state.payload, scope, idx) }, isEmpty)
      return setClientErrors(nextErrors)
    }),
  )
}
