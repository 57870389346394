import { chain, compact, each, isEmpty, isObject, mapValues, reduce, result } from 'lodash'

import Payload from '../models/Payload'
import { validateRequired, validateRequiredNumber, validateWordCountGte } from './validation/validate'

export function validate(payload: Payload, scope?: string, idx?: number) {
  switch ([scope, idx].join('/')) {
  case '/': return validateBasicInfo(payload)
  case 'tastes/': return validateTasteCategory(payload)
  case `items/${idx}`: return { ...validateItem(payload, idx), ...validateItemsExistence(payload) }
  case `hairstyles/${idx}`: return { ...validateHairstyle(payload, idx), ...validateItemsExistence(payload) }
  case `nails/${idx}`: return { ...validateNail(payload, idx), ...validateItemsExistence(payload) }
  default: return {}
  }
}

export function validateAll(payload: Payload) {
  return {
    ...validateItemsExistence(payload),
    ...validateBasicInfo(payload),
    ...validateTasteCategory(payload),
    ...validateItems(payload),
    ...validateHairstyleItems(payload),
    ...validateNailItems(payload),
  }
}

const validateItemsExistence = (payload: Payload) => {
  const { items, hairstyleItems, nailItems } = payload

  if (isEmpty(chain([items, hairstyleItems, nailItems]).flatten().compact().value())) {
    return { root: ['no items'] }
  }

  return { root: [] }
}

type Validation = (value: any) => string
interface Config {
  [key: string]: Validation[]
}
interface Messages {
  [key: string]: string[]
}

const runValidations = (payload: Payload, config: Config) => {
  const msgs: Messages = {}
  each(config, (validations, key) => {
    const attr = result(payload, key)
    msgs[key] = compact(validations.map((validation) => validation(attr)))
  })
  return msgs
}

const validateFace = (payload: Payload, idx: number) => {
  const config = { [`faces[${idx}].boundingBox`]: isObject(payload.faces[idx]) ? [validateRequired] : [] }
  return runValidations(payload, config)
}

const validateGenreTagNames = (payload: Payload) => {
  const config = { genreTagNames: [validateRequired] }
  return runValidations(payload, config)
}

const validateSceneTagNames = (payload: Payload) => {
  const config = { sceneTagNames: [validateWordCountGte(2)] }
  return runValidations(payload, config)
}

const validateBasicInfo = (payload: Payload) => {
  // Currently, we only support one face.
  return { ...validateFace(payload, 0), ...validateGenreTagNames(payload), ...validateSceneTagNames(payload) }
}

const validateTasteCategory = (payload: Payload) => {
  return runValidations(payload, { tasteCategory: [validateRequired] })
}

const validateItems = (payload: Payload) => {
  return reduce(payload.items, (msgs, _, idx) => ({ ...msgs, ...validateItem(payload, idx) }), {})
}

const validateItem = (payload: Payload, idx: number) => {
  const prefix = `items[${idx}]`
  const config = {
    [`${prefix}.category`]: [validateRequired],
    [`${prefix}.layer`]: [validateRequiredNumber],
    [`${prefix}.boundingBox`]: [validateRequired],
    [`${prefix}.keywordTagNames`]: [validateRequired],
  }
  return mapValues(runValidations(payload, config), (msg) => isEmpty(payload.items[idx]) ? [] : msg)
}

const validateHairstyleItems = (payload: Payload) => {
  return reduce(payload.hairstyleItems, (msgs, _, idx) => ({ ...msgs, ...validateHairstyle(payload, idx) }), {})
}
const validateHairstyle = (payload: Payload, idx: number) => {
  const prefix = `hairstyleItems[${idx}]`
  const config = {
    [`${prefix}.hairstyleTagNames`]: [validateRequired],
    [`${prefix}.genre`]: [validateRequired],
    [`${prefix}.boundingBox`]: [validateRequired],
  }
  return mapValues(runValidations(payload, config), (msg) => isEmpty(payload.hairstyleItems[idx]) ? [] : msg)
}

const validateNailItems = (payload: Payload) => {
  return reduce(payload.nailItems, (msgs, _, idx) => ({ ...msgs, ...validateNail(payload, idx) }), {})
}
const validateNail = (payload: Payload, idx: number) => {
  const prefix = `nailItems[${idx}]`
  const config = {
    [`${prefix}.nailTagNames`]: [validateRequired],
    [`${prefix}.genre`]: [validateRequired],
    [`${prefix}.boundingBox`]: [validateRequired],
  }
  return mapValues(runValidations(payload, config), (msg) => isEmpty(payload.nailItems[idx]) ? [] : msg)
}
