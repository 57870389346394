import React, { useEffect, useCallback } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router'

import Main from './Main'
import Menu from './Menu'
import SubmitBtn from '../components/SubmitBtn'
import LoadingComponent from '../../../Loading'
import Error from '../components/Error'
import ErrorMessage from '../components/ErrorMessage'
import performValidation from '../actions/performValidation'
import prepareRequest from '../actions/prepareRequest'
import setAttribute from '../actions/setAttribute'

import styles from './App.scss'

const canSendSelector = (state) => state.canSend
const serverErrorsSelector = (state) => state.serverErrors
const isLoadingSelector = (state) => state.isLoading

const VERSION = '1.0'

const App = ({
  snapId, photoId, recognitionId, auditId, imageSrc,
  genreTagOptions, tasteCategoryOptions, itemCategoryOptions,
}) => {
  const isLoading = useSelector(isLoadingSelector)
  const canSend = useSelector(canSendSelector)
  const serverErrors = useSelector(serverErrorsSelector)

  const withTitle = (photoId || snapId) ? true : false
  const withGenreTagSelector = recognitionId ? false : true
  const isUpdate = snapId || recognitionId

  const dispatch = useDispatch()

  useEffect(() => {
    if (photoId) {
      dispatch(setAttribute('blogPhotoId', photoId, false))
    }
  }, [photoId, dispatch])

  useEffect(() => {
    if (auditId) {
      dispatch(performValidation())
    }
  }, [auditId, dispatch])

  const submitHandler = useCallback(() => {
    if (snapId) {
      let path = `/snaps/${snapId}.json`
      if (auditId) {
        path = `${path}?audit_id=${auditId}`
      }
      dispatch(prepareRequest(path, 'PATCH'))
    } else if (recognitionId) {
      dispatch(prepareRequest(`https://${process.env.HOST}/scnnr_results/${recognitionId}.json`, 'PUT', { 'X-SCNNR-RESULT-MODIFIER': `cbk/snap-editor/${VERSION}` }))
    } else {
      dispatch(prepareRequest('/snaps.json', 'POST'))
    }
  }, [dispatch, snapId, auditId, recognitionId])

  return (
    <div>
      <Loading isLoading={isLoading} />
      <div className={styles['container']}>
        <ServerErrors serverErrors={serverErrors} />
        <section className={styles['menu']}><Menu /></section>
        <Main
          imageSrc={imageSrc}
          withTitle={withTitle}
          withGenreTagSelector={withGenreTagSelector}
          genreTagOptions={genreTagOptions}
          tasteCategoryOptions={tasteCategoryOptions}
          itemCategoryOptions={itemCategoryOptions} />
        <SubmitBtn
          onSubmit={submitHandler}
          disabled={!canSend}
          text={I18n.t(`javascript.components.snaps.editor.${isUpdate ? 'update_btn' : 'publish_btn'}`)} />
      </div>
    </div>
  )
}

const Loading = ({ isLoading }) => {
  if (!isLoading) {
    return null
  }
  return <LoadingComponent className={styles['loading']} innerClassName={styles['loading__inner']} />
}

const ServerErrors = ({ serverErrors }) => {
  if (_.isEmpty(serverErrors)) { return null }
  if (_.isString(serverErrors)) { return <ErrorMessage text={serverErrors} /> }
  return (
    <ul className={styles['error-area']}>
      {_.map(serverErrors, (_, key) => {
        return (
          <li key={key}>
            <Error
              errorKeys={{ client: null, server: key }}
              format={I18n.t(`javascript.components.snaps.editor.errors.${key}`)} />
          </li>
        )
      })}
    </ul>
  )
}

export default withRouter(App)
