import React from 'react'
import PropTypes from 'prop-types'

import AutocompletableInput from '../AutocompletableInput'
import Caption from '../Caption'
import Error from '../Error'

export default class NailTagSelector extends React.PureComponent {
  static propTypes = {
    errorKeys: PropTypes.shape({
      client: PropTypes.string,
      server: PropTypes.string,
    }),
    index: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    tagNames: PropTypes.array.isRequired,
    onUpdate: PropTypes.func,
  }

  handleUpdate = tags => {
    this.props.onUpdate(tags)
  }

  handleBlur = () => {
    if (this.errorContainer) {
      this.errorContainer.clearServerError()
    }
  }

  render() {
    return (
      <div>
        {this.props.errorKeys ? (
          <Error
            errorRef={ref => this.errorContainer = ref}
            errorKeys={this.props.errorKeys}
            format={I18n.t('javascript.components.snaps.editor.errors.nail_items/nail_tag_names')} />
        ) : null}
        <Caption text={I18n.t('javascript.components.snaps.editor.main.nail.nail_tags.caption')} />
        <AutocompletableInput
          key={`nail${this.props.index}`}
          tagType='nail'
          multi={true}
          tagNames={this.props.tagNames}
          onBlur={this.handleBlur}
          onUpdate={this.handleUpdate} />
      </div>
    )
  }
}
