import React from 'react'

import TagSelector from '../TagSelector'

const KeywordTagSelector = ({ tagNames, errorKeys, onUpdate }) => (
  <TagSelector
    tagType='keyword'
    errorFormat={I18n.t('javascript.components.snaps.editor.errors.items/keyword_tag_names')}
    captionText={I18n.t('javascript.components.snaps.editor.main.item.keyword_tags.caption')}
    tagNames={tagNames}
    errorKeys={errorKeys}
    onUpdate={onUpdate}
    stockPreview={true}
  />
)

export default KeywordTagSelector
