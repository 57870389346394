import React from 'react'
import _ from 'lodash'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { restoreDraft } from './editor/lib/draft'

import App from './editor/containers/App'
import configureStore, { history } from './editor/store/configureStore'
import { mergeDefaultFeatures, mergeDefaultKeywordsForCategory } from './editor/values/ItemCategory'

const getInitialState = () => {
  let initialState = window.__SNAP_EDITOR_INITIAL_STATE__ || {}
  const currentDraftId = window.__SNAP_EDITOR_DRAFT_IDENTIFIER__
  const useDefaults = window.__SNAP_EDITOR_USE_DEFAULTS__ || false

  delete window.__SNAP_EDITOR_INITIAL_STATE__
  delete window.__SNAP_EDITOR_DRAFT_IDENTIFIER__
  delete window.__SNAP_EDITOR_USE_DEFAULTS__

  if (useDefaults) { initialState = stateWithDefaults(initialState) }
  if (_.isString(currentDraftId)) {
    const draft = restoreDraft(currentDraftId)
    return _.isEmpty(draft) ? { ...initialState, draftIdentifier: currentDraftId } : draft
  }

  return initialState
}

const stateWithDefaults = (state) => (
  { ...state, payload: payloadWithDefaults(state.payload) }
)

const payloadWithDefaults = (payload) => (
  { ...payload, items: itemsWithDefaults(payload.items) }
)

const itemsWithDefaults = (items) => (
  items.map((item) => (
    {
      ...item,
      keywordTagNames: mergeDefaultKeywordsForCategory(item.category, item.keywordTagNames),
      featureTagNames: mergeDefaultFeatures(item.featureTagNames),
    }
  ))
)

const store = configureStore(getInitialState())

const Editor = props =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App {...props} />
    </ConnectedRouter>
  </Provider>

export default Editor
