import RequestHeaders from '../models/RequestHeaders'

export const SEND_REQUEST = 'SEND_REQUEST'

export interface SendRequestAction {
  type: typeof SEND_REQUEST
  url: string
  method: string
  headers: RequestHeaders
}

export default function sendRequest(url: string, method: string, headers: RequestHeaders = {}): SendRequestAction {
  return { type: SEND_REQUEST, url, method, headers }
}
