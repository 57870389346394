import { replace } from 'connected-react-router'
import { result } from 'lodash'
import { Action } from 'redux'
import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, withLatestFrom } from 'rxjs/operators'

import { REMOVE_ITEM, RemoveItemAction } from '../actions/removeItem'
import { RoutePathForItemGenre } from '../values/ItemGenre'

// TODO: avoid `any` after migrating store to TS
export default function(action$: ActionsObservable<Action>, state$: StateObservable<any>): Observable<Action> {
  return action$.pipe(
    ofType<RemoveItemAction>(REMOVE_ITEM),
    withLatestFrom(state$),
    map(([action, state]) => {
      const partialPathname = `${RoutePathForItemGenre[action.itemGenre]}/`
      const remainingItems = (result(state.payload, action.itemGenre) as any[]).length

      if (remainingItems === 0) {
        return replace({ pathname: '/' })
      }

      if (action.itemIndex < remainingItems) {
        return replace({ pathname: `${partialPathname}${action.itemIndex}` })
      }

      return replace({ pathname: `${partialPathname}${remainingItems - 1}` })
    }),
  )
}
