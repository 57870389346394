import React from 'react'
import PropTypes from 'prop-types'

import Caption from '../Caption'
import GenreTagSelectBtn from './GenreTagSelectBtn'
import Error from '../Error'

export default class GenreTagSelector extends React.PureComponent {
  static propTypes = {
    errorKeys: PropTypes.shape({
      client: PropTypes.string,
      server: PropTypes.string,
    }),
    tagNames: PropTypes.array.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
  }

  handleClick = (name, isActive) => {
    if (this.errorContainer) {
      this.errorContainer.clearServerError()
    }
    if (isActive) {
      if (this.props.onDeselect) { this.props.onDeselect(name) }
    } else {
      if (this.props.onSelect) { this.props.onSelect(name) }
    }
  }

  render() {
    return (
      <div>
        {this.props.errorKeys ? (
          <Error
            errorRef={ref => this.errorContainer = ref}
            errorKeys={this.props.errorKeys}
            format={I18n.t('javascript.components.snaps.editor.errors.genre_tag_names')} />
        ) : null}
        <Caption text={I18n.t('javascript.components.snaps.editor.main.basic_information.genre_tags.caption')} />
        <ul>
          {this.props.options.map(genre => (
            <li key={genre.key}>
              <GenreTagSelectBtn
                name={genre.name}
                isActive={this.props.tagNames.includes(genre.name)}
                onClick={this.handleClick} />
            </li>
          ))}
        </ul>
      </div>
    )
  }
}
