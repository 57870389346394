import ClientErrors from '../models/ClientErrors'
import Payload from '../models/Payload'

export const draftKey = 'cbkSnapDraft'

export function restoreDraft(identifier: string) {
  const draft = JSON.parse(localStorage.getItem(draftKey)) || {}
  if (draft.draftIdentifier !== identifier) { return {} }
  return draft
}

export function storeDraft(draftIdentifier: string, payload: Payload, clientErrors: ClientErrors) {
  const data = { draftIdentifier, payload, clientErrors }
  localStorage.setItem(draftKey, JSON.stringify(data))
}

export function purgeDraft() {
  localStorage.removeItem(draftKey)
}
