import { defaults, find, mapKeys, snakeCase } from 'lodash'
import { Observable } from 'rxjs'
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax'
import { catchError } from 'rxjs/operators'
import parseUrl from 'url-parse'

import ApiError from './api/apiError'
import { Headers, Options, Queries } from './api/types'

class Api {
  private static DEFAULT_OPTIONS: Options = { camelcase: true }

  private static getCSRFToken(): null | string {
    const elem: undefined | HTMLMetaElement = find(document.getElementsByTagName('meta'), (meta) => {
      return meta.name === 'csrf-token'
    })
    return elem == null ? null : elem.content
  }

  private url: string
  private withCredentials: boolean
  private options: Options

  constructor(url: string, queries: Queries = {}, options: Options = {}) {
    const parsedUrl = parseUrl(url, true)
    parsedUrl.set('query', { ...parsedUrl.query, ...mapKeys(queries, (_, key) => snakeCase(key)) })
    this.url = parsedUrl.toString()
    this.withCredentials = parsedUrl.host !== process.env.HOST || parsedUrl.pathname.indexOf('/api/') !== 0

    this.options = defaults(options, Api.DEFAULT_OPTIONS)
  }

  public get(headers: Headers = {}) { return this.send('GET', null, headers) }
  public post(body: object, headers: Headers = {}) { return this.send('POST', body, headers) }
  public put(body: object, headers: Headers = {}) { return this.send('PUT', body, headers) }
  public patch(body: object, headers: Headers = {}) { return this.send('PATCH', body, headers) }
  public delete(headers: Headers = {}) { return this.send('DELETE', null, headers) }

  public send(method: string, body: object | null, headers: Headers = {}): Observable<AjaxResponse> {
    return ajax({
      async: true,
      body,
      headers: { ...this.defaultHeaders(method), ...headers },
      method,
      responseType: 'json',
      url: this.url,
      withCredentials: this.withCredentials,
    }).pipe(
      catchError<AjaxResponse, Observable<never>>((error: AjaxError) => { throw new ApiError(error) }),
    )
  }

  private defaultHeaders(method: string) {
    const headers: Headers = { 'Content-Type': 'application/json' }
    if (this.options.camelcase) { headers['X-Key-Inflection'] = 'camel' }
    switch (method.toUpperCase()) {
    case 'POST':
    case 'PUT':
    case 'PATCH':
    case 'DELETE': return { 'X-CSRF-Token': Api.getCSRFToken(), ...headers }
    default: return headers
    }
  }
}

export default function api(url: string, params: Queries = {}, options: Options = {}): Api {
  return new Api(url, params, options)
}

export { Api, ApiError }
