import { START_SENDING  } from '../actions/startSending'
import { STOP_LOADING  } from '../actions/stopLoading'
import { SET_CLIENT_ERRORS } from '../actions/setClientErrors'
import { SET_SERVER_ERRORS } from '../actions/setServerErrors'

const initialState = false

export default function(state = initialState, action) {
  switch (action.type) {
  case START_SENDING: return true
  case STOP_LOADING:
  case SET_CLIENT_ERRORS:
  case SET_SERVER_ERRORS:
    return false
  default: return state
  }
}
