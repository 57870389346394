import React from 'react'
import PropTypes from 'prop-types'

import connectMainContent from './connectMainContent'
import PhotoPreview from '../components/PhotoPreview'
import TasteSelector from '../components/Tastes/TasteSelector'

import setAttribute from '../actions/setAttribute'

import styles from './MainContent.scss'

const mapStateToProps = state => {
  return {
    tasteCategory: state.payload.tasteCategory,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onUpdateTasteCategory: category => dispatch(setAttribute('tasteCategory', category)),
  }
}

@connectMainContent('tastes', mapStateToProps, mapDispatchToProps)
export default class Tastes extends React.Component {
  static propTypes = {
    imageSrc: PropTypes.string.isRequired,
    boundingBox: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number,
      bottom: PropTypes.number,
      right: PropTypes.number
    }),
    contentKey: PropTypes.string,
    tasteCategory: PropTypes.string,
    tasteCategoryOptions: PropTypes.object.isRequired,
    onUpdateTasteCategory: PropTypes.func,
  }

  render() {
    return (
      <div className={styles['main-content__container']}>
        <div className={styles['main-content__preview']}>
          <PhotoPreview disabled={true} imageSrc={this.props.imageSrc} />
        </div>
        <div className={styles['main-content__form-area']}>
          <div className={styles['main-content__form-item']}>
            <TasteSelector
              errorKeys={{ client: 'tasteCategory', server: 'tasteCategory' }}
              value={this.props.tasteCategory}
              options={this.props.tasteCategoryOptions}
              onUpdate={this.props.onUpdateTasteCategory} />
          </div>
        </div>
      </div>
    )
  }
}
