import React from 'react'

import styles from './Description.scss'

const Description = () => (
  <div className={styles['description']}>
    <p>{I18n.t('javascript.components.snaps.editor.main.description')}</p>
  </div>
)

export default Description
