import classnames from 'classnames'
import React from 'react'

import styles from './Preview.module.scss'

interface PreviewProps {
  disabled: boolean
  imageUrl: string
  linkUrl?: string
}

const Preview = ({ disabled, imageUrl, linkUrl }: PreviewProps) => {
  const content = (
    <div className={classnames(styles['content'], { [styles['content--disabled']]: disabled })}>
      <img className={styles['content__image']} src={imageUrl} />
    </div>
  )

  if (!linkUrl || disabled) { return content }
  return <a className={styles['link']} target='_blank' href={linkUrl}>{content}</a>
}

export default React.memo(Preview)
