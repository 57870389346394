import GlueySession from './CBK/GlueySession'

export default class CBK {
  public _alertMessages: string[] // tslint:disable-line
  public glueySession: GlueySession | null

  constructor() {
    this._alertMessages = []
  }

  public alert(message: string) {
    this._alertMessages.push(message)
  }

  public refreshGlueySession() {
    this.glueySession = GlueySession.refreshCurrent()
  }
}
