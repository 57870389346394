import { ofType } from 'redux-observable'
import { NEVER } from 'rxjs'
import { map, filter, tap, flatMap, withLatestFrom } from 'rxjs/operators'

import { SET_CLIENT_ERRORS } from '../actions/setClientErrors'

import { storeDraft } from '../lib/draft'

export default function(action$, state$) {
  return action$.pipe(
    ofType(SET_CLIENT_ERRORS),
    map(action => action.errors),
    withLatestFrom(state$),
    map(([errors, state]) => [state.draftIdentifier, state.payload, errors]),
    filter(([identifier]) => identifier != null),
    tap(([identifier, payload, errors]) => storeDraft(identifier, payload, errors)),
    flatMap(() => NEVER),
  )
}
