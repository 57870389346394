import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import request from 'superagent'

import Item from './poster/item'
import EmptyContent from './poster/empty_content'
import Loading from '../Loading'
import WaterfallLayout from './waterfall_layout'

const PAGINATION_MARGIN = 40

class Poster extends React.Component {
  constructor(props) {
    super(props)
    this.state = { snaps: this.props.filter(props.snaps), page: 1, loading: false }
    this.scrollHandler = _.throttle(() => this.paginateIfNeeded(), 100)
    this.updateLayoutHandler = this.paginateIfNeeded.bind(this)
  }

  render() {
    const { loading } = this.state
    return (
      <div>
        {this.renderContent()}
        {loading ? <Loading /> : <div></div>}
      </div>
    )
  }

  renderContent() {
    const { snaps } = this.state
    const { id, itemWidth, emptyMessage, linkTarget, scheduleRedraw } = this.props
    if (this.isEmpty) { return <EmptyContent id={id} message={emptyMessage} /> }
    return (
      <WaterfallLayout id={id} itemWidth={itemWidth} onUpdateLayout={this.updateLayoutHandler} scheduleRedraw={scheduleRedraw}>
        {snaps.map((snap, idx) => <this.props.item key={idx} data={snap} width={itemWidth} linkTarget={linkTarget} />)}
      </WaterfallLayout>
    )
  }

  componentDidMount() {
    this.enablePagination()
  }

  componentDidUpdate() {
    if (this.state.loading) { this.fetchNext() }
  }

  get rootElement() { return document.getElementById(this.props.id) }
  get isEmpty() { return _.isEmpty(this.state.snaps) }
  get query() {
    return _.assign(
      { includes: 'descriptions,user', page: this.state.page, per_page: this.props.perPage },
      this.props.queries,
      { categories: this.props.queries.categories?.join(',') }
    )
  }

  paginate() {
    if (this.state.loading) { return false }
    if (this.props.lastPage <= this.state.page) {
      this.disablePagination()
      return false
    }
    this.setState({ page: this.state.page + 1, loading: true })
    return true
  }

  paginateIfNeeded() {
    const el = this.rootElement
    const bottom = el.getBoundingClientRect().top + el.offsetTop + el.offsetHeight
    if (window.innerHeight + PAGINATION_MARGIN > bottom) { this.paginate() }
  }

  enablePagination() {
    window.document.addEventListener('scroll', this.scrollHandler)
  }

  disablePagination() {
    window.document.removeEventListener('scroll', this.scrollHandler)
  }

  fetchNext() {
    request.get(`${this.props.apiBase}/snaps/search.json`)
      .query(this.query)
      .set(this.props.apiHeaders)
      .end((err, res) => {
        if (err != null) { return this.handleError(err) }
        this.setState({ snaps: this.state.snaps.concat(this.props.filter(res.body.snaps)), loading: false })
      })
  }

  handleError(error) {
    // TODO: implement
    console.error(error) // eslint-disable-line no-console
  }
}

Poster.defaultProps = {
  itemWidth: 145,
  apiHeaders: {},
  item: Item,
  filter: snaps => snaps,
  scheduleRedraw: false,
}

Poster.propTypes = {
  id: PropTypes.string.isRequired,
  snaps: PropTypes.array.isRequired,
  perPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  itemWidth: PropTypes.number.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  apiBase: PropTypes.string.isRequired,
  apiHeaders: PropTypes.object.isRequired,
  linkTarget: PropTypes.string,
  item: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  scheduleRedraw: PropTypes.bool.isRequired,
}

export default Poster
