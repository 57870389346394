import React from 'react'
import PropTypes from 'prop-types'

import Caption from '../Caption'
import Error from '../Error'
import FaceStatusSelectBtn from './FaceStatusSelectBtn'

const i18nScope = 'javascript.components.snaps.editor.main.basic_information.faces'

export default class FaceStatusSelector extends React.PureComponent {
  static propTypes = {
    currentStatus: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func,
  }

  selectOn = () => {
    this.select(true)
  }

  selectOff = () => {
    this.select(false)
  }

  select(status) {
    if (this.errorContainer) {
      this.errorContainer.clearServerError()
    }
    if (this.props.onUpdate) { this.props.onUpdate(status) }
  }

  render() {
    return (
      <div>
        {this.props.errorKeys ? (
          <Error
            errorRef={ref => this.errorContainer = ref}
            errorKeys={this.props.errorKeys}
            format={I18n.t('javascript.components.snaps.editor.errors.faces/bounding_box')} />
        ) : null}
        <Caption text={I18n.t(`${i18nScope}.caption`)} />
        <FaceStatusSelectBtn
          text={I18n.t(`${i18nScope}.with_face`)}
          isActive={this.props.currentStatus === true}
          onClick={this.selectOn} />
        <FaceStatusSelectBtn
          text={I18n.t(`${i18nScope}.without_face`)}
          isActive={this.props.currentStatus === false}
          onClick={this.selectOff} />
      </div>
    )
  }
}
