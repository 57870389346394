export const PERFORM_VALIDATION = 'PERFORM_VALIDATION'

export interface PerformValidationAction {
  targetPath: string | null
  type: typeof PERFORM_VALIDATION
}

export default function performValidation(targetPath: string | null = null): PerformValidationAction {
  return { type: PERFORM_VALIDATION, targetPath }
}
