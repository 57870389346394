import { connect } from 'react-redux'
import _ from 'lodash'

import setAttribute from '../actions/setAttribute'

const connectMainContent = (key, mapStateToProps, mapDispatchToProps, mergeProps, options) => {
  const _mapStateToProps = (state, ownProps) => {
    const index = ownProps.match.params.index || 0
    const photoPreviewProps = {
      contentKey: key,
      boundingBox: _.result(state, `payload[${key}][${index}].boundingBox`)
    }
    if (!_.isFunction(mapStateToProps)) { return photoPreviewProps }
    return Object.assign({}, photoPreviewProps, mapStateToProps(state, ownProps))
  }

  const _mapDispatchToProps = (dispatch, ownProps) => {
    const idx = ownProps.match.params.index || 0
    const photoPreviewProps = {
      onCropComplete: bBox => dispatch(setAttribute(`${key}[${idx}].boundingBox`, bBox)),
    }
    if (!_.isFunction(mapDispatchToProps)) { return photoPreviewProps }
    return Object.assign({}, photoPreviewProps, mapDispatchToProps(dispatch, ownProps))
  }

  return connect(_mapStateToProps, _mapDispatchToProps, mergeProps, options)
}

export default connectMainContent
