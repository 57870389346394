import classnames from 'classnames'
import moment from 'moment'
import React from 'react'

import styles from './Caption.module.scss'

interface CaptionProps {
  createdAt: string
  id: number
}

const Caption = ({ createdAt, id }: CaptionProps) => {
  const createdAtMoment: moment.Moment = moment(createdAt, moment.ISO_8601)
  const createdAtDate: string = createdAtMoment.isValid() ? createdAtMoment.format('YYYY.MM.DD.') : ''
  const displayId = 'No.' + `000000000${id}`.slice(-10)

  return (
    <div className={styles['caption']}>
      <span className={classnames(styles['item'], styles['item--left'])}>{displayId}</span>
      <span className={classnames(styles['item'], styles['item--right'])}>{createdAtDate}</span>
    </div>
  )
}

export default React.memo(Caption)
