import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Loading from './Loading'
import styles from './image.module.scss'

class Image extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true }
    this.onLoad = this.onLoad.bind(this)
    this.onError = this.onError.bind(this)
  }

  render() {
    const { title, src, style, className } = this.props
    const contents = [
      <img key='img' alt={title} className={className} src={src} style={style} onLoad={this.onLoad} onError={this.onError} />
    ]
    if (this.state.loading) {
      contents.push(<Loading key='loading' className={styles['loading']} innerClassName={styles['loading__inner']} />)
    }
    return <div style={{ position: 'relative' }}>{contents}</div>
  }

  onLoad(event) {
    this.setState({ loading: false })
    if (_.isFunction(this.props.onLoad)) { this.props.onLoad(event) }
  }

  onError(event) {
    this.setState({ loading: false })
    if (_.isFunction(this.props.onError)) { this.props.onError(event) }
  }
}

Image.defaultProps = {
}

Image.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  onLoad: PropTypes.func,
  onError: PropTypes.func,
}

export default Image
