import * as React from 'react'

import styles from './Bubble.module.scss'

interface BubbleProps {
  text: string
}

const Bubble = ({ text }: BubbleProps) => (
  <div className={styles['container']}>
    <div className={styles['bubble']}>
      <p>{text}</p>
    </div>
  </div>
)

export default React.memo(Bubble)
