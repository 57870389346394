import React from 'react'

import styles from './ErrorMessage.scss'

export interface ErrorMessageProps {
  text: string
}

const ErrorMessage = ({ text }: ErrorMessageProps) => (
  <div className={styles['error']}>
    <p>{text}</p>
  </div>
)

export default ErrorMessage
